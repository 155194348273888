import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone
} from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { MapsAPILoader, MouseEvent } from "@agm/core";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { log } from "util";

@Component({
  selector: "app-edit-bar-profile",
  templateUrl: "./edit-bar-profile.component.html",
  styleUrls: ["./edit-bar-profile.component.css"]
})
export class EditBarProfileComponent implements OnInit {
  //Varialbe Declration
  foodMenuFiles: Array<{ file: File, preview: string }> = [];
  drinkMenuFiles: Array<{ file: File, preview: string }> = [];
  roleId: any = 0;
  
  profileData: any = [];
  ktData: any = [];
  profileForm: FormGroup;
  
  bankForm: FormGroup;
  taxForm: FormGroup;
  cancelTiming: FormGroup;
  kycForm: FormGroup;
  bannerForm: FormGroup;
  timingForm: FormGroup;
  timingFormTwo: FormGroup;
  kitchenTimingForm: FormGroup;
  is_texes_status = "";
  multipleEnable ="";

  invalidLogin: boolean = false;
  validForgot: boolean = false;
  invalidForgot: boolean = false;
  showForgotForm: boolean = false;
  showLoginForm: boolean = true;
  allAreas = [];
  allCities = [];
  allTimeZone =[];
  allcurrency =[];
  allStates = [];
  items = [];
  allCountries = [];
  kycLicense: any = []; //["Pan Card", "Aadhar Card", "GST Registration", "FSSAI License"];
  logoPath = null;
  cancelChequePath = null;
  KycprePath = null;
  coverImgPath = null;
  chooseFileLabel = "Choose a file..";
  BannerprePath = null;
  kycPath = this.cs.barKycPath();
  bannerPath = this.cs.barBannerPath();
  menuPath = this.cs.barMenuPath();
  kycDocs = [];
  banners = [];
  barFoodMenu: any = [];
  barBevMenu: any = [];
  FileErrorMessage = false;
  FileErrorMessageBanner = false;
  FileErrorMessageLogo = false;
  FileErrorMessageCover = false;
  croppedImage: any = "";
  imageChangedEvent: any = "";
  imageChangedEventLogo: any = "";
  imageSave = false;
  imageSaveLogo = false;

  foodFileErrorMessage = false;
  drinkFileErrorMessage = false;
  foodMenuLable = "Choose a file...";
  drinkMenuLable = "Choose a file...";
  foodMenuPath = null;
  drinkMenuPath = null;
  menuImgPath = "";
  foodTimeExist = false;
  drinkTimeExist = false;
  bannerCoverType: string = "banner";

  //isLoadingResults = false;
  //sundaytime = "21:40"; //For testing
  //Google Map variable
  title: string = "";
  base64Img: any = "";
  latitude: number;
  longitude: number;
  zoom: number;
  customAddress: string;
  private geoCoder;
  isMapShow: boolean = false;
  TimeErrorMessage = false;
  barId: any = null;
  activeParam: any = false;
  documentpath = "";
  bannerpathpath = "";
  barBannerPaththumb = this.cs.barBannerPaththumb();
  showModal = true;
  bannerId: any = null;
  bannerImageSave: any = null;
  logoImageSave: any = null;
  skipcrop = false;
  //currentDay: any = null;
  //barUserId: number;
  //barStatus: number;
  generateCodeCancelTimingStatus: boolean = false;
  preOrderOfferCancelTimingStatus: boolean = false;
  preOrderCancelTimingStatus: boolean = false;
  tableBookingCancelTimingStatus: boolean = false;
  eventBookingCancelTimingStatus: boolean = false;


  @ViewChild("search", { static: false })
  public searchElementRef: ElementRef;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    public cs: CommonService,
    public myapp: AppComponent,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.barId = localStorage.getItem("barId");
    this.roleId = localStorage.getItem("roleId");
    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.barId = id;
        this.activeParam = true;
      }
    });

    // Get the Current Day, to validate opening-closing time
    // Sunday - Saturday (0-6)
    /*var d = new Date();
    this.currentDay = d.getDay();
    console.log("today: " +this.currentDay);*/

    //Load the License types from app component
    this.kycLicense = this.myapp.licenseTypes;

    //Call the Bar Profile
    this.loadBarProfile();

    //Call the countries
    this.loadCountries();

    //Call the loadStates function
    //this.loadStates();

    //Profile Form Model Declration with form validation for the fields
    this.profileForm = this.fb.group({
      id: [this.barId],
      barName: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(200)
          //Validators.pattern("^[a-zA-Z ()]+$")
        ]
      ],
      companyName: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(200)
        ]
      ],
      table_booking_amount: [""],
      orderCancellationTime:[""],
      logo: [""],
      timezone: [""],
      currency_id: [""],
      addressfull:[""],
      email: ["", [Validators.required, Validators.email]],
      phone: ["", [Validators.required, Validators.pattern("^[0-9]{10}$")]],
      phone2:[""],
      cuisines: [
        "",
        [
          //Validators.required,
          // Validators.minLength(10),
          Validators.maxLength(500)
        ]
      ],
      about: [
        "",
        [
          //Validators.required,
          Validators.minLength(20),
          Validators.maxLength(500)
        ]
      ],
      address: [
        "",
        [
          Validators.required
          //Validators.minLength(10),
          //Validators.maxLength(220)
        ]
      ],
      countryId: ["0", [Validators.required]],
      stateId: ["0", [Validators.required]],
      cityId: ["0", [Validators.required]],
      areaId: [""],
      localArea: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(200)
        ]
      ],
      lat: [""],
      long: [""],
      wifi_name:[""],
      wifi_password:[""],
      additionalPhones: this.fb.array([])
    });

    //Bank Form Model Declration with form validation for the fields
    this.bankForm = this.fb.group({
      accountName: ["", [Validators.required]],
      accountNo: ["", [Validators.required]],
      bankName: ["", [Validators.required]],
      onlinePaymentName:["",],
      commission:["",],
      bankBranch: ["", [Validators.required]],
      ifsc: ["", [Validators.required]],
      cancelCheque: ["", []]
    });

    //Tax Form Model Declration with form validation for the fields
    this.taxForm = this.fb.group({
      id: [this.barId],
      cgst: ["", [Validators.required, Validators.pattern("^[0-9() . ]+$")]],
      sgst: ["", [Validators.required, Validators.pattern("^[0-9() . ]+$")]],
      vat: ["", [Validators.required, Validators.pattern("^[0-9() . ]+$")]],
      serviceCharge: ["", Validators.pattern("^[0-9() . ]+$")],
      serviceChargeTax: ["", Validators.pattern("^[0-9() . ]+$")],
      serviceChargeTax2: ["", Validators.pattern("^[0-9() . ]+$")],
      gstNumber:[""]
    });
    this.cancelTiming = new FormGroup({
      generateCodeCancelTimingStatus: new FormControl(false),
      generateCodeCancelTiming: new FormControl(''),
      preOrderOfferCancelTimingStatus: new FormControl(false),
      preOrderOfferCancelTiming: new FormControl(''),
      preOrderCancelTimingStatus: new FormControl(false),
      preOrderCancelTiming: new FormControl(''),
      tableBookingCancelTimingStatus: new FormControl(false),
      tableCancelTiming: new FormControl(''),
      eventBookingCancelTimingStatus: new FormControl(false),
      eventCancelTiming: new FormControl(''),
    });
    

    
    //Tiing Form Model Declration with form validation for the fields
    this.timingForm = this.fb.group({
      //barId: [localStorage.getItem("barId")],
      sunday: this.fb.group({
        day1: ["sunday"],
        day1OpenTime: [""],
        day1CloseTime: [""],
        day1Id: [""]
      }),
      monday: this.fb.group({
        day2: ["monday"],
        day2OpenTime: [""],
        day2CloseTime: [""],
        day2Id: [""]
      }),
      tuesday: this.fb.group({
        day3: ["tuesday"],
        day3OpenTime: [""],
        day3CloseTime: [""],
        day3Id: [""]
      }),
      wednesday: this.fb.group({
        day4: ["wednesday"],
        day4OpenTime: [""],
        day4CloseTime: [""],
        day4Id: [""]
      }),
      thursday: this.fb.group({
        day5: ["thursday"],
        day5OpenTime: [""],
        day5CloseTime: [""],
        day5Id: [""]
      }),
      friday: this.fb.group({
        day6: ["friday"],
        day6OpenTime: [""],
        day6CloseTime: [""],
        day6Id: [""]
      }),
      saturday: this.fb.group({
        day7: ["saturday"],
        day7OpenTime: [""],
        day7CloseTime: [""],
        day7Id: [""]
      })
    });

    //Tiing Form Model Declration with form validation for the fields
    this.timingFormTwo = this.fb.group({
      //barId: [localStorage.getItem("barId")],
      sunday: this.fb.group({
        day1: ["sunday"],
        day1OpenTimeTwo: [""],
        day1CloseTimeTwo: [""],
        day1Id: [""]
      }),
      monday: this.fb.group({
        day2: ["monday"],
        day2OpenTimeTwo: [""],
        day2CloseTimeTwo: [""],
        day2Id: [""]
      }),
      tuesday: this.fb.group({
        day3: ["tuesday"],
        day3OpenTimeTwo: [""],
        day3CloseTimeTwo: [""],
        day3Id: [""]
      }),
      wednesday: this.fb.group({
        day4: ["wednesday"],
        day4OpenTimeTwo: [""],
        day4CloseTimeTwo: [""],
        day4Id: [""]
      }),
      thursday: this.fb.group({
        day5: ["thursday"],
        day5OpenTimeTwo: [""],
        day5CloseTimeTwo: [""],
        day5Id: [""]
      }),
      friday: this.fb.group({
        day6: ["friday"],
        day6OpenTimeTwo: [""],
        day6CloseTimeTwo: [""],
        day6Id: [""]
      }),
      saturday: this.fb.group({
        day7: ["saturday"],
        day7OpenTimeTwo: [""],
        day7CloseTimeTwo: [""],
        day7Id: [""]
      })
    });

    //KycForm Form Model Declration with form validation for the fields
    this.kycForm = this.fb.group({
      barId: [this.barId],
      licenseTypeId: ["", [Validators.required]],
      fileName: ["", [Validators.required]]
    });

    //bannerForm Form Model Declration with form validation for the fields
    this.bannerForm = this.fb.group({
      barId: [this.barId],
      mediaName: ["", [Validators.required]],
      mediaType: ["banner", [Validators.required]]
    });

    // Kitchen Timing Form
    this.kitchenTimingForm = this.fb.group({
      foodEndTime: ["", [Validators.required]],
      foodMenu: [""],
      drinkEndTime: ["", [Validators.required]],
      drinkMenu: [""]
    });

    //map load
    this.mapsAPILoader.load().then(() => {
      console.log('ppp');
      
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();
      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        {
          types: [],
          // componentRestrictions: { country: "IN" }
        }
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          this.isMapShow = true;
          //console.log("isMapShow stattus is true");
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          // console.log(place);
          

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.customAddress = place.formatted_address;
          //alert('lat:'+this.latitude +' long:'+this.longitude);
          this.profileForm.controls["lat"].patchValue(this.latitude);
          this.profileForm.controls["long"].patchValue(this.longitude);
          this.profileForm.controls["address"].patchValue(this.customAddress);
          this.zoom = 10;
        });
      });
    });
  }

  
  //Load Bar Profile
  loadBarProfile() {
    //this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const barData = new FormData();
    barData.append("id", this.barId);
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getBarProfileData").subscribe(
      (response: any) => {
      console.log( response.data.profile);
      
        
        
        if (response.status == 1) {
          // console.log(response.data.profile);
          // alert('pp');
          
          this.profileData = response.data.profile;
          this.ktData = response.data.kitchenTiming;
          this.items = response.data.items;
          this.is_texes_status= response.data.profile.is_texes_status,
          
          // User can edit days, if profile is not activated
          //if(this.profileData['status'] != 1)
          //this.currentDay = null;

          //setValue can be used in place of patchValue, but setValue accepts all the controls of the form
          this.profileForm.patchValue({
            id: response.data.profile.barId,
            barName: response.data.profile.barName,
            companyName: response.data.profile.companyName,
            table_booking_amount: response.data.profile.table_booking_amount,
            orderCancellationTime:response.data.profile.orderCancellationTime,
            email: response.data.profile.email,
            phone: response.data.profile.phone,
            phone2: response.data.profile.phone2,
            wifi_name:response.data.profile.wifi_name,
            wifi_password:response.data.profile.wifi_password,
            cuisines: response.data.profile.cuisines,
            about: response.data.profile.about,
            address: response.data.profile.address,
            addressfull:response.data.profile.addressfull,
            countryId: response.data.profile.countryId,
            stateId: response.data.profile.stateId,
            cityId: response.data.profile.cityId,
            areaId: response.data.profile.areaId,
            localArea: response.data.profile.localArea,
            lat: response.data.profile.latitude,
            long: response.data.profile.longitude,
            currency_id:response.data.profile.currency_id,
            timezone:response.data.profile.timezone,
          });
          this.additionalPhones.clear();

        // If additionalPhones are available, add them to the FormArray
        // if (response.data.profile.additionalPhones && response.data.profile.additionalPhones.length) {
        //   response.data.profile.additionalPhones.forEach((phone: string) => {
        //     this.addPhoneControl(phone); // Add phone controls dynamically
        //   });
        // }
        if (response.data.profile.additionalPhones && response.data.profile.additionalPhones.length > 0) {
          response.data.profile.additionalPhones.forEach((phone: string) => {
            // Add each phone number as a new form group in the array
            this.additionalPhones.push(this.fb.group({ phone: [phone] }));
          });
        }

          if (response.data.profile.address) this.isMapShow = true;

          //set lat and lang
          this.latitude = parseFloat(response.data.profile.latitude);
          this.longitude = parseFloat(response.data.profile.longitude);
          //this.barStatus = response.data.profile.barStatus;
          //this.barUserId = response.data.profile.barUserId;
          //this.setCurrentLocation();
          //Load the cities of the state
          this.loadStates();
          
          this.loadCities(null);
          this.loadAreas(null);
          if (response.data.profile.logo)
            this.logoPath = this.cs.barLogoPath() + response.data.profile.logo;

          //SEt values in Tax Form Model
          this.taxForm.patchValue({
            cgst: response.data.profile.cgst,
            sgst: response.data.profile.sgst,
            vat: response.data.profile.vat,
            serviceCharge: response.data.profile.serviceCharge,
            serviceChargeTax: response.data.profile.serviceChargeTax,
            serviceChargeTax2: response.data.profile.serviceChargeTax2,
            gstNumber: response.data.profile.gst_number,
            
          });


          // //SEt values in cancel Timing Form Model
          this.cancelTiming.patchValue({
            generateCodeCancelTiming: response.data.profile.generateCodeCancelTiming,
            preOrderOfferCancelTiming: response.data.profile.preOrderOfferCancelTiming,
            preOrderCancelTiming: response.data.profile.preOrderCancelTiming,
            tableCancelTiming: response.data.profile.tableCancelTiming,
            eventCancelTiming: response.data.profile.eventCancelTiming,

            
            
          });
          // console.log('lpooooo');
          // console.log(this.cancelTiming);
          this.generateCodeCancelTimingStatus = response.data.profile.generateCodeCancelTiming > 0 ? true : false;
          this.preOrderCancelTimingStatus= response.data.profile.preOrderOfferCancelTiming > 0 ? true : false;
          this.tableBookingCancelTimingStatus= response.data.profile.tableCancelTiming > 0 ? true : false;
          this.eventBookingCancelTimingStatus= response.data.profile.eventCancelTiming > 0 ? true : false;          

          //SEt values in Bank Form Model
          this.bankForm.patchValue({
            accountName: response.data.profile.accountName,
            accountNo: response.data.profile.accountNo,
            bankName: response.data.profile.bankName,
            onlinePaymentName:response.data.profile.onlinePaymentName,
            commission:response.data.profile.commission,
            bankBranch: response.data.profile.bankBranch,
            ifsc: response.data.profile.ifsc
          });
          
          
          if (response.data.profile.cancelCheque)
            this.cancelChequePath =
              this.cs.cancelChequePath() + response.data.profile.cancelCheque;

          //SEt values in Kitchen Timing Model
          if (this.ktData.length) {
            this.kitchenTimingForm.patchValue({
              foodEndTime: this.cs.timeWithoutSeconds(this.ktData[0].endTime),
              drinkEndTime: this.cs.timeWithoutSeconds(this.ktData[1].endTime)
              /*foodMenu: this.ktData[0].menu,
              drinkMenu: this.ktData[1].menu*/
            });
          } else {
            this.foodTimeExist = false;
            this.drinkTimeExist = false;
          }

          //Set the Timings
          this.timingForm.patchValue({
            sunday: {
              day1: response.data.hours[0].weekDay,
              day1OpenTime: this.cs.timeWithoutSeconds(
                response.data.hours[0].openingTime
              ),
              day1CloseTime: this.cs.timeWithoutSeconds(
                response.data.hours[0].closingTime
              ),
              day1Id: response.data.hours[0].id
            },
            monday: {
              day2: response.data.hours[1].weekDay,
              day2OpenTime: this.cs.timeWithoutSeconds(
                response.data.hours[1].openingTime
              ),
              day2CloseTime: this.cs.timeWithoutSeconds(
                response.data.hours[1].closingTime
              ),
              day2Id: response.data.hours[1].id
            },
            tuesday: {
              day3: response.data.hours[2].weekDay,
              day3OpenTime: this.cs.timeWithoutSeconds(
                response.data.hours[2].openingTime
              ),
              day3CloseTime: this.cs.timeWithoutSeconds(
                response.data.hours[2].closingTime
              ),
              day3Id: response.data.hours[2].id
            },
            wednesday: {
              day4: response.data.hours[3].weekDay,
              day4OpenTime: this.cs.timeWithoutSeconds(
                response.data.hours[3].openingTime
              ),
              day4CloseTime: this.cs.timeWithoutSeconds(
                response.data.hours[3].closingTime
              ),
              day4Id: response.data.hours[3].id
            },
            thursday: {
              day5: response.data.hours[4].weekDay,
              day5OpenTime: this.cs.timeWithoutSeconds(
                response.data.hours[4].openingTime
              ),
              day5CloseTime: this.cs.timeWithoutSeconds(
                response.data.hours[4].closingTime
              ),
              day5Id: response.data.hours[4].id
            },
            friday: {
              day6: response.data.hours[5].weekDay,
              day6OpenTime: this.cs.timeWithoutSeconds(
                response.data.hours[5].openingTime
              ),
              day6CloseTime: this.cs.timeWithoutSeconds(
                response.data.hours[5].closingTime
              ),
              day6Id: response.data.hours[5].id
            },
            saturday: {
              day7: response.data.hours[6].weekDay,
              day7OpenTime: this.cs.timeWithoutSeconds(
                response.data.hours[6].openingTime
              ),
              day7CloseTime: this.cs.timeWithoutSeconds(
                response.data.hours[6].closingTime
              ),
              day7Id: response.data.hours[6].id
            }
          });
           this.multipleEnable = response.data.multipleEnable;
          this.timingFormTwo.patchValue({
            sunday: {
              day1: response.data.hoursTwo[0].weekDay,
              day1OpenTimeTwo: this.cs.timeWithoutSeconds(
                response.data.hoursTwo[0].openingTimeTwo
              ),
              day1CloseTimeTwo: this.cs.timeWithoutSeconds(
                response.data.hoursTwo[0].closingTimeTwo
              ),
              day1Id: response.data.hoursTwo[0].id
            },
            monday: {
              day2: response.data.hoursTwo[1].weekDay,
              day2OpenTimeTwo: this.cs.timeWithoutSeconds(
                response.data.hoursTwo[1].openingTimeTwo
              ),
              day2CloseTimeTwo: this.cs.timeWithoutSeconds(
                response.data.hoursTwo[1].closingTimeTwo
              ),
              day2Id: response.data.hoursTwo[1].id
            },
            tuesday: {
              day3: response.data.hoursTwo[2].weekDay,
              day3OpenTimeTwo: this.cs.timeWithoutSeconds(
                response.data.hoursTwo[2].openingTimeTwo
              ),
              day3CloseTimeTwo: this.cs.timeWithoutSeconds(
                response.data.hoursTwo[2].closingTimeTwo
              ),
              day3Id: response.data.hoursTwo[2].id
            },
            wednesday: {
              day4: response.data.hoursTwo[3].weekDay,
              day4OpenTimeTwo: this.cs.timeWithoutSeconds(
                response.data.hoursTwo[3].openingTimeTwo
              ),
              day4CloseTimeTwo: this.cs.timeWithoutSeconds(
                response.data.hoursTwo[3].closingTimeTwo
              ),
              day4Id: response.data.hoursTwo[3].id
            },
            thursday: {
              day5: response.data.hoursTwo[4].weekDay,
              day5OpenTimeTwo: this.cs.timeWithoutSeconds(
                response.data.hoursTwo[4].openingTimeTwo
              ),
              day5CloseTimeTwo: this.cs.timeWithoutSeconds(
                response.data.hoursTwo[4].closingTimeTwo
              ),
              day5Id: response.data.hoursTwo[4].id
            },
            friday: {
              day6: response.data.hoursTwo[5].weekDay,
              day6OpenTimeTwo: this.cs.timeWithoutSeconds(
                response.data.hoursTwo[5].openingTimeTwo
              ),
              day6CloseTimeTwo: this.cs.timeWithoutSeconds(
                response.data.hoursTwo[5].closingTimeTwo
              ),
              day6Id: response.data.hoursTwo[5].id
            },
            saturday: {
              day7: response.data.hours[6].weekDay,
              day7OpenTimeTwo: this.cs.timeWithoutSeconds(
                response.data.hoursTwo[6].openingTimeTwo
              ),
              day7CloseTimeTwo: this.cs.timeWithoutSeconds(
                response.data.hoursTwo[6].closingTimeTwo
              ),
              day7Id: response.data.hoursTwo[6].id
            }
          });
          //console.log("timingForm" + response.data.hours[0].openingTime);
          //console.log("timingForm" + response.data.hours[1].openingTime);
          //console.log("vjjfnhjun" + this.day1OpenTime);
          // this.open = this.day1OpenTime.toString();

          //Set the KYC doc in array
          this.kycDocs = response.data.kycDocs;
          //Set the Banners in array
          this.banners = response.data.banners;
          //console.log("Banner:" + response.data.banners);
          //this.timingForm.value.toString();

          this.barFoodMenu = [];
          this.barBevMenu = [];
          let barMenu = response.data.barMenu;
          for (let i = 0; i < barMenu.length; i++) {
            if (barMenu[i].categoryId == 1) this.barFoodMenu.push(barMenu[i]);
            else this.barBevMenu.push(barMenu[i]);
          }
        } else {
          //this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }


  changeMultipalTime(){
    
    let value: any = 0;
    let alertMsg = "";
    if (this.multipleEnable) {
      value = 1;
      // this.multipleEnable = '1';
      alertMsg = "Are you sure you want to start multiple time?";
    } else {
      value = 0;
      // this.multipleEnable = '0';
      // this.disableClick = true;
      alertMsg = "Are you sure you want to close multiple time?"; // You can not turn it ON it today.
    }
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: alertMsg
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
     
      if (result == true) {
        // alert('lll');
        this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;

        const barData = new FormData();
        barData.append("barId", localStorage.getItem("barId"));
        barData.append("multipalTimeStatus", value);
        barData.append("token", localStorage.getItem("token"));
          //alert('oop');
        this.bs.callAPI(barData, "changeMultipalTimeStatus").subscribe(
          (response: any) => {
            if (response.status == 1) {
              //console.log(response);
              if (value == 1) {
                this.multipleEnable = value;
              } else {
                this.multipleEnable = value;
              }
              this.myapp.showSuccessAlert = true;
              this.myapp.successMsg = response.message;
              this.myapp.isLoadingResults = false;
            } else {
              this.myapp.showErrorAlert = true;
              this.myapp.errorMsg = response.message;
              this.myapp.isLoadingResults = false;
              
              this.loadBarProfile();
             
            }
          },
          error => {
            console.log(error);
          }
        );
      } else {
       
        this.loadBarProfile();
      }
    });
  }
  // Function to add new phone controls
addPhoneControl(phone: string = '') {
  this.additionalPhones.push(this.fb.control(phone));
}
  // banner image crop
  // imageCropped(event: ImageCroppedEvent) {
  //   console.log("Cropping: " + JSON.stringify(event));
  //   this.BannerprePath = event.base64;
  //   this.bannerForm.get("mediaName").setValue(event.base64);
  //   this.base64Img = "ok";
  // }
  imageCropped(event: ImageCroppedEvent) {
    console.log("Cropping: " + JSON.stringify(event));
    const croppedImage = event.base64;
    
    // Optional: Adjust image quality settings if your library supports it
    // For example, ngx-image-cropper has a default setting for quality
    
    this.BannerprePath = croppedImage;
    this.bannerForm.get("mediaName").setValue(croppedImage);
    this.base64Img = "ok";
  }
  hideCropImg() {
    this.imageSave = false;
  }
  /*skipCropImg()
  {
    this.imageSave = false;
    this.bannerForm.get("mediaName").setValue(this.bannerImageSave);
  }*/

  // Logo image crop
  /*imageCroppedLogo(event: ImageCroppedEvent) {
    console.log("Cropping: " + JSON.stringify(event));
    this.logoPath = event.base64;
    this.profileForm.get("logo").setValue(event.base64);
    this.base64Img = "ok";
  }
  hideCropImgLogo() {
    this.imageSaveLogo = false;
  }*/
  /*skipCropImgLogo()
  {
    this.imageSaveLogo = false;
    this.bannerForm.get("logo").setValue(this.logoImageSave);
  }*/

  //on File select, put the selected file in Profile Form model variable
  onFileSelect(event, form) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let docName = file.name;
      //console.log("File Size: " + file.size);
      if (form == "profileForm") {
        var fileMimes = file.type.split("/");
        var fileType = fileMimes[0];

        if (fileType === "image") {
          this.imageSaveLogo = true;
          this.imageChangedEventLogo = event;
          this.FileErrorMessageLogo = false;
          this.logoImageSave = this.profileForm.get("logo").setValue(file);
          this.profileForm.get("logo").setValue(file);
          //Preveiw of the image only on images
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = _event => {
            this.logoPath = reader.result;
          };
          return;
        } else {
          this.FileErrorMessageLogo = true;
        }
      } else if (form == "kycForm") {
        var fileMimes = file.type.split("/");
        var fileType = fileMimes[0];
        var fileType2 = fileMimes[1];

        if (fileType === "image" || fileType2 == "pdf") {
          this.KycprePath = null;
          this.FileErrorMessage = false;
          this.kycForm.get("fileName").setValue(file);
          //Preveiw of the image only on images
          if (fileType === "image") {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = _event => {
              this.KycprePath = reader.result;
            };
            this.chooseFileLabel = "Choose a file..";
          } else if (fileType2 === "pdf") {
            this.chooseFileLabel = docName;
          }
          return;
        } else {
          this.FileErrorMessage = true;
        }
      } else if (form == "bannerForm") {
        var fileMimes = file.type.split("/");
        var fileType = fileMimes[0];
        if (fileType === "image") {
          this.imageSave = true;
          this.FileErrorMessageBanner = false;
          this.imageChangedEvent = event;
          this.bannerImageSave = this.bannerForm
            .get("mediaName")
            .setValue(file);
          this.bannerForm.get("mediaName").setValue(file);
          //Preveiw of the image only on images
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = _event => {
            this.BannerprePath = reader.result;
          };
          return;
        } else {
          this.FileErrorMessageBanner = true;
        }
      } else if (form == "bankForm") {
        var fileMimes = file.type.split("/");
        var fileType = fileMimes[0];
        if (fileType === "image") {
          this.imageChangedEvent = event;
          this.bankForm.get("cancelCheque").setValue(file);
          //Preveiw of the image only on images
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = _event => {
            this.cancelChequePath = reader.result;
          };
          return;
        }
      } else if (form == "cover") {
        var fileMimes = file.type.split("/");
        var fileType = fileMimes[0];
        var fileType2 = fileMimes[1];

        if (fileType === "image") {
          this.coverImgPath = null;
          this.FileErrorMessageCover = false;
          this.bannerForm.get("mediaName").setValue(file);
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = _event => {
            this.coverImgPath = reader.result;
          };
          this.chooseFileLabel = "Choose a file..";
          this.base64Img = "";
          return;
        } else {
          this.FileErrorMessage = true;
        }
      }
    }
  }

  // onFoodMenuChange(event) {
  //   if (event.target.files.length > 0) {
  //     const file = event.target.files[0];
  //     let docName = file.name;
  //     var fileMimes = file.type.split("/");
  //     var fileType = fileMimes[0];
  //     var fileType2 = fileMimes[1];

  //     if (fileType === "image" || fileType2 == "pdf") {
  //       this.foodMenuPath = null;
  //       this.foodFileErrorMessage = false;
  //       this.kitchenTimingForm.get("foodMenu").setValue(file);

  //       //Preveiw of the image only on images
  //       if (fileType === "image") {
  //         var reader = new FileReader();
  //         reader.readAsDataURL(file);
  //         reader.onload = _event => {
  //           this.foodMenuPath = reader.result;
  //         };
  //         this.foodMenuLable = "Choose a file..";
  //       } else if (fileType2 === "pdf") {
  //         this.foodMenuLable = docName;
  //       }
  //       return;
  //     } else {
  //       this.foodFileErrorMessage = true;
  //     }
  //   }
  // }
  onFoodMenuChange(event: any) {
    const files = event.target.files;
    if (files.length > 0) {
      this.foodMenuPath = null;
      this.foodFileErrorMessage = false;
      this.foodMenuFiles = [];  // Clear previous files
      this.foodMenuLable = 'Choose files..';  // Update label
  
      // Loop through selected files
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        let docName = file.name;
        let fileMimes = file.type.split("/");
        let fileType = fileMimes[0];  // Check if it's image
        let fileType2 = fileMimes[1];  // Check if it's pdf
  
        // Only allow image or pdf file types
        if (fileType === "image" || fileType2 === "pdf") {
          // Handle image preview
          if (fileType === "image") {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e: any) => {
              this.foodMenuFiles.push({ file: file, preview: e.target.result });
            };
          } else if (fileType2 === "pdf") {
            this.foodMenuFiles.push({ file: file, preview: './assets/img/pdf-icon.png' });
          }
  
          // Set the form control value for backend submission
          this.kitchenTimingForm.get("foodMenu").setValue(files);
  
          // Update the label with the first file name or multiple files
          this.foodMenuLable = files.length === 1 ? docName : `${files.length} files selected`;
        } else {
          this.foodFileErrorMessage = true;
          this.foodMenuLable = 'Invalid file format! Please select images or PDFs.';
        }
      }
    }
  }
  

  // onDrinkMenuChange(event) {
  //   if (event.target.files.length > 0) {
  //     const file = event.target.files[0];
  //     let docName = file.name;
  //     var fileMimes = file.type.split("/");
  //     var fileType = fileMimes[0];
  //     var fileType2 = fileMimes[1];

  //     if (fileType === "image" || fileType2 == "pdf") {
  //       this.drinkMenuPath = null;
  //       this.drinkFileErrorMessage = false;
  //       this.kitchenTimingForm.get("drinkMenu").setValue(file);

  //       //Preveiw of the image only on images
  //       if (fileType === "image") {
  //         var reader = new FileReader();
  //         reader.readAsDataURL(file);
  //         reader.onload = _event => {
  //           this.drinkMenuPath = reader.result;
  //         };
  //         this.drinkMenuLable = "Choose a file..";
  //       } else if (fileType2 === "pdf") {
  //         this.drinkMenuLable = docName;
  //       }
  //       return;
  //     } else {
  //       this.drinkFileErrorMessage = true;
  //     }
  //   }
  // }
  onDrinkMenuChange(event:any) {
    const files = event.target.files;
    if (files.length > 0) {
      this.drinkMenuPath = null;
      this.drinkFileErrorMessage = false;
      this.drinkMenuFiles = [];  // Clear previous files
      this.drinkMenuLable = 'Choose files..';  // Update label
  
      // Loop through selected files
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        let docName = file.name;
        let fileMimes = file.type.split("/");
        let fileType = fileMimes[0];  // Check if it's image
        let fileType2 = fileMimes[1];  // Check if it's pdf
  
        // Only allow image or pdf file types
        if (fileType === "image" || fileType2 === "pdf") {
          // Handle image preview
          if (fileType === "image") {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e: any) => {
              this.drinkMenuFiles.push({ file: file, preview: e.target.result });
            };
          } else if (fileType2 === "pdf") {
            this.drinkMenuFiles.push({ file: file, preview: './assets/img/pdf-icon.png' });
          }
  
          // Set the form control value for backend submission
          this.kitchenTimingForm.get("drinkMenu").setValue(files);
  
          // Update the label with the first file name or multiple files
          this.drinkMenuLable = files.length === 1 ? docName : `${files.length} files selected`;
        } else {
          this.drinkFileErrorMessage = true;
          this.drinkMenuLable = 'Invalid file format! Please select images or PDFs.';
        }
      }
    }
    
  }

  //Load areas, on City change event
  loadAreas(event) {
    this.myapp.hideAlerts();
    const cityId = this.profileForm.get("cityId").value;
    if (event != null) {
      this.profileForm.patchValue({
        address: ""
      });
    }
    if (cityId != "0") {
      const formData = new FormData();
      formData.append("cityId", cityId);
      formData.append("token", localStorage.getItem("token"));
      this.bs.callAPI(formData, "getAllAreas").subscribe(
        (response: any) => {
          //console.log(response);
          if (response.status == 1) {
            this.allAreas = response.data;
          } else {
            this.allAreas = [];
            /*this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;*/
          }
        },
        error => {
          console.log(error);
        }
      );
    } else this.allCities = [];
  }

  //Load Countries
  loadCountries() {
    this.myapp.hideAlerts();
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(formData, "getAllCountries").subscribe(
      (response: any) => {
        // console.log(response);
        
        if (response.status == 1) {
          this.allCountries = response.data;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load States
  loadStates() {
    this.myapp.hideAlerts();
    const countryId = this.profileForm.get("countryId").value;
    if (countryId != "0") {
      const formData = new FormData();
      formData.append("countryId", countryId);
      formData.append("token", localStorage.getItem("token"));
      this.bs.callAPI(formData, "getAllStates").subscribe(
        (response: any) => {
          //console.log(response);
          if (response.status == 1) {
            this.allStates = response.data;
            this.allTimeZone = response.time_zone;
            this.allcurrency = response.currencies
          }
        },
        error => {
          console.log(error);
        }
      );
    } else this.allStates = [];
  }

  //Load cities, on States change event
  loadCities(event) {
    this.myapp.hideAlerts();
    const stateId = this.profileForm.get("stateId").value;

    if (stateId != "0") {
      //this.myapp.isLoadingResults = true;
      const formData = new FormData();
      formData.append("stateId", stateId);
      formData.append("token", localStorage.getItem("token"));
      this.bs.callAPI(formData, "getAllCities").subscribe(
        //this.myapp.isLoadingResults = false;
        (response: any) => {
          //console.log(response);
          if (response.status == 1) {
            /*this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.myapp.isLoadingResults = false;*/
            this.allCities = response.data;
            //console.log(this.allCities);
          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        },
        error => {
          console.log(error);
        }
      );
    } else this.allCities = [];
  }

  documentviewer(name) {
    this.documentpath = name;
  }

  bannerviewer(name, bannerId) {
    this.bannerpathpath = name;
    this.bannerId = bannerId;
  }

   // Add new phone number control to the array
   addPhoneNumber() {
    
    const phoneFormGroup = this.fb.group({
      phone: ['', [Validators.pattern(/^\d{10}$/)]]// Validation for 10-digit phone number
    });
    this.additionalPhones.push(phoneFormGroup);
  }

  // Remove a phone number from the array
  removePhoneNumber(index: number) {
    this.additionalPhones.removeAt(index);
  }
  //Profile Form Submission
  onProfileSubmit() {
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;
   
    const formData = new FormData();
    formData.append("id", this.profileForm.get("id").value);
    formData.append("barName", this.profileForm.get("barName").value);
    formData.append("companyName", this.profileForm.get("companyName").value);
    formData.append("table_booking_amount", this.profileForm.get("table_booking_amount").value);
    formData.append("orderCancellationTime", this.profileForm.get("orderCancellationTime").value);
    formData.append("logo", this.profileForm.get("logo").value);
    formData.append("cuisines", this.profileForm.get("cuisines").value);
    formData.append("about", this.profileForm.get("about").value);
    formData.append("address", this.profileForm.get("address").value);
    formData.append("addressfull", this.profileForm.get("addressfull").value);
    formData.append("countryId", this.profileForm.get("countryId").value);
    formData.append("stateId", this.profileForm.get("stateId").value);
    formData.append("cityId", this.profileForm.get("cityId").value);
    formData.append("areaId", this.profileForm.get("areaId").value);
    formData.append("localArea", this.profileForm.get("localArea").value);
    formData.append("latitude", this.profileForm.get("lat").value);
    formData.append("longitude", this.profileForm.get("long").value);
    formData.append("email", this.email.value);
    formData.append("phone", this.phone.value);
    formData.append("phone2", this.phone2.value);
    formData.append("wifi_name", this.wifi_name.value);
    formData.append("wifi_password", this.wifi_password.value);
    formData.append("currency_id", this.currency_id.value);
    formData.append("timezone", this.timezone.value);
    formData.append("token", localStorage.getItem("token"));
    // console.log(formData);
    // Append additional phone numbers from the FormArray
    console.log(this.additionalPhones);
    
    this.additionalPhones.controls.forEach((control, index) => {
      formData.append(`additionalPhones[${index}]`, control.get('phone').value);
    });
    
   
    this.bs
      .callAPI(formData, "editBarProfile") //this.profileForm.value
      .subscribe(response => {
        // console.log(response);
        
        if (response.status === 1) {
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.myapp.isLoadingResults = false;
          this.isMapShow = false;

          this.myapp.loginBarName = this.profileForm.get("barName").value;
          if (this.logoPath) this.myapp.loginBarLogo = this.logoPath;
          //console.log("isMapShow stattus is true");
          //this.logoPath = this.cs.barLogoPath() + response.data.profile.logo;
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.myapp.isLoadingResults = false;
        }
      });
  }

  //Tax Form Submission
  onTaxSubmit() {
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;

    const formData = new FormData();
    formData.append("id", this.taxForm.get("id").value);
    formData.append("cgst", this.taxForm.get("cgst").value);
    formData.append("gstNumber", this.taxForm.get("gstNumber").value);
    formData.append("sgst", this.taxForm.get("sgst").value);
    formData.append("vat", this.taxForm.get("vat").value);
    formData.append("serviceCharge", this.taxForm.get("serviceCharge").value);
    formData.append(
      "serviceChargeTax",
      this.taxForm.get("serviceChargeTax").value
    );
    formData.append(
      "serviceChargeTax2",
      this.taxForm.get("serviceChargeTax2").value
    );
    formData.append("token", localStorage.getItem("token"));
    //console.log(this.taxForm.value);
    this.bs.callAPI(formData, "editBarTaxes").subscribe(response => {
      if (response.status === 1) {
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        this.myapp.isLoadingResults = false;
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
  }

  //Tax Form Submission
  onCancelTimingSubmit() {
    alert('lll');
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;

    const formData = new FormData();
    formData.append("id", this.barId);
    formData.append("generateCodeCancelTiming", this.generateCodeCancelTiming.value);
    formData.append("preOrderOfferCancelTiming", this.preOrderOfferCancelTiming.value);
    formData.append("preOrderCancelTiming", this.preOrderCancelTiming.value);
    formData.append("tableCancelTiming", this.tableCancelTiming.value);
    formData.append("eventCancelTiming", this.eventCancelTiming.value);


    // formData.append("generateCodeCancelTimingStatus", this.generateCodeCancelTimingStatus);
    // formData.append("preOrderOfferCancelTimingStatus", this.preOrderOfferCancelTimingStatus);
    // formData.append("preOrderCancelTimingStatus", this.preOrderCancelTimingStatus);
    // formData.append("tableBookingCancelTimingStatus", this.tableBookingCancelTimingStatus);
    // formData.append("eventBookingCancelTimingStatus", this.eventBookingCancelTimingStatus);
   
    formData.append("token", localStorage.getItem("token"));
    console.log(formData);

    this.bs.callAPI(formData, "editBarcancelTiming").subscribe(response => {
      console.log(response);
      
      if (response.status === 1) {
        console.log(response);
        
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        this.myapp.isLoadingResults = false;
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
  }

  //Bank Form Submission
  onBankFormSubmit() {
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;

    const formData = new FormData();
    formData.append("id", this.barId);
    formData.append("accountName", this.accountName.value);
    formData.append("accountNo", this.accountNo.value);
    formData.append("bankName", this.bankName.value);
    if ((this.roleId == 1 || this.roleId ==5)) {
      formData.append("onlinePaymentName", this.onlinePaymentName.value);
      console.log(this.commission.value);
      
      if (this.commission.value =="") {
        console.log('poiu');
        formData.append("commission",'0');
      } else {
        formData.append("commission",this.commission.value);
      }
      
      
      
    } else {
      
    }
    // formData.append("commission",this.commission.value);
    formData.append("userType", localStorage.getItem("userType"));
    formData.append("bankBranch", this.bankBranch.value);
    formData.append("ifsc", this.ifsc.value);
    formData.append("cancelCheque", this.cancelCheque.value ? this.cancelCheque.value: null);
    formData.append("token", localStorage.getItem("token"));
     

    // alert(formData.append("onlinePaymentName", this.onlinePaymentName.value));
    //console.log(this.taxForm.value);
    this.bs.callAPI(formData, "editBarBankInfo").subscribe(response => {
      console.log(response);
      
      if (response.status === 1) {
        this.profileData.cancelCheque = response.data;
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        this.myapp.isLoadingResults = false;
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
  }

  //Timing Form Submission
  onTimingSubmit() {
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;

    const barTiming = JSON.stringify(this.timingForm.value);

    const timeData = new FormData();
    timeData.append("barTiming", barTiming);
    timeData.append("barId", this.barId);
    timeData.append("token", localStorage.getItem("token"));
    //console.log("jnhjfcv" + this.day2OpenTime);
    //console.log(barTiming);
    this.bs.callAPI(timeData, "addEditBarTiming").subscribe(response => {
      if (response.status === 1) {
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        this.myapp.isLoadingResults = false;
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
  }


  //Timing Form Submission
  onTimingSubmitTwo() {
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;

    const barTiming = JSON.stringify(this.timingFormTwo.value);

    const timeData = new FormData();
    timeData.append("barTimingTwo", barTiming);
    timeData.append("barId", this.barId);
    timeData.append("multipleEnable", this.multipleEnable);
    
    timeData.append("token", localStorage.getItem("token"));
    //console.log("jnhjfcv" + this.day2OpenTime);
    //console.log(barTiming);
    this.bs.callAPI(timeData, "addEditBarTimingTwo").subscribe(response => {
      if (response.status === 1) {
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        this.myapp.isLoadingResults = false;
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
  }

  //KYC Form Submission
  onKycSubmit() {
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;
    const kycData = new FormData();
    kycData.append("barId", this.kycForm.get("barId").value);
    kycData.append("licenseTypeId", this.kycForm.get("licenseTypeId").value);
    kycData.append("fileName", this.kycForm.get("fileName").value);
    kycData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(kycData, "addLicenseDoc").subscribe(response => {
      if (response.status === 1) {
        this.myapp.isLoadingResults = false;
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        this.kycForm.reset();
        this.KycprePath = null;
        this.chooseFileLabel = "Choose a file..";

        this.kycForm.patchValue({
          barId: this.barId,
          licenseTypeId: ""
        });

        //Add the latest added image in kycDocs Array
        this.kycDocs.unshift(response.data); //Add in the top & Push in the last
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
  }

  //KYC Form Submission
  onBannerSubmit() {
    this.myapp.hideAlerts();
    this.imageSave = false;
    //console.log("this.bannerForm.get.value" + this.bannerForm.get("mediaName").value)
    this.myapp.isLoadingResults = true;
    const bannerData = new FormData();
    bannerData.append("barId", this.bannerForm.get("barId").value);
    bannerData.append("mediaName", this.bannerForm.get("mediaName").value);
    bannerData.append("mediaType", this.bannerForm.get("mediaType").value);
    bannerData.append("base64Img", this.base64Img);
    bannerData.append("token", localStorage.getItem("token"));

    this.bs
      .callAPI(bannerData, "addBarBanners") //this.profileForm.value
      .subscribe(response => {
        if (response.status === 1) {
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.myapp.isLoadingResults = false;
          this.BannerprePath = null;

          //Add the latest added image in Array
          if (this.bannerForm.get("mediaType").value == "banner")
            this.banners[0].mediaName = response.data.mediaName;
          else {
            this.profileData.panelCoverImg = response.data.mediaName;
            this.myapp.loginBarCoverImg =
              this.cs.barBannerPath() + response.data.mediaName;
          }

          this.bannerForm.reset();
          this.bannerForm.patchValue({
            barId: this.barId,
            mediaType: "banner"
          });
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.myapp.isLoadingResults = false;
        }
      });
  }

  //Media Delete Function
  deleteMedia(type, id, index) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want to delete this?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      if (result == true) {
        this.myapp.hideAlerts();
        const mediaData = new FormData();
        mediaData.append("id", id);
        mediaData.append("type", type);
        mediaData.append("barId", this.barId);
        mediaData.append("token", localStorage.getItem("token"));
        this.bs.callAPI(mediaData, "deleteBarMedia").subscribe(response => {
          if (response.status === 1) {
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            //Delete the key from array
            if (type == "kyc") this.kycDocs.splice(index, 1);
            else if (type == "banner") this.banners.splice(index, 1);
            else if (type == "cover") this.profileData.panelCoverImg = null;
          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        });
      }
    });
    return false;
  }

  //Media Menu Function
  deleteMenu(id, index, menu) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want to delete this menu?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      if (result == true) {
        this.myapp.hideAlerts();
        const formData = new FormData();
        formData.append("id", id);
        formData.append("token", localStorage.getItem("token"));
        this.bs.callAPI(formData, "deleteBarMenu").subscribe(response => {
          if (response.status === 1) {
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            //Delete the key from array
            if (menu == "food") this.barFoodMenu.splice(index, 1);
            else this.barBevMenu.splice(index, 1);
          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        });
      }
    });
    return false;
  }

  //Function to copy timing
  copyTiming() {
    let openTime = this.timingForm.get("sunday").value.day1OpenTime;
    let closeTime = this.timingForm.get("sunday").value.day1CloseTime;

    //Set the Timings
    this.timingForm.patchValue({
      monday: {
        day2OpenTime: this.cs.timeWithoutSeconds(openTime),
        day2CloseTime: this.cs.timeWithoutSeconds(closeTime)
      },
      tuesday: {
        day3OpenTime: this.cs.timeWithoutSeconds(openTime),
        day3CloseTime: this.cs.timeWithoutSeconds(closeTime)
      },
      wednesday: {
        day4OpenTime: this.cs.timeWithoutSeconds(openTime),
        day4CloseTime: this.cs.timeWithoutSeconds(closeTime)
      },
      thursday: {
        day5OpenTime: this.cs.timeWithoutSeconds(openTime),
        day5CloseTime: this.cs.timeWithoutSeconds(closeTime)
      },
      friday: {
        day6OpenTime: this.cs.timeWithoutSeconds(openTime),
        day6CloseTime: this.cs.timeWithoutSeconds(closeTime)
      },
      saturday: {
        day7OpenTime: this.cs.timeWithoutSeconds(openTime),
        day7CloseTime: this.cs.timeWithoutSeconds(closeTime)
      }
    });
  }

  //Function to copy timing
  copyTimingTwo() {
    let openTime = this.timingFormTwo.get("sunday").value.day1OpenTimeTwo;
    let closeTime = this.timingFormTwo.get("sunday").value.day1CloseTimeTwo;

    //Set the Timings
    this.timingFormTwo.patchValue({
      monday: {
        day2OpenTimeTwo: this.cs.timeWithoutSeconds(openTime),
        day2CloseTimeTwo: this.cs.timeWithoutSeconds(closeTime)
      },
      tuesday: {
        day3OpenTimeTwo: this.cs.timeWithoutSeconds(openTime),
        day3CloseTimeTwo: this.cs.timeWithoutSeconds(closeTime)
      },
      wednesday: {
        day4OpenTimeTwo: this.cs.timeWithoutSeconds(openTime),
        day4CloseTimeTwo: this.cs.timeWithoutSeconds(closeTime)
      },
      thursday: {
        day5OpenTimeTwo: this.cs.timeWithoutSeconds(openTime),
        day5CloseTimeTwo: this.cs.timeWithoutSeconds(closeTime)
      },
      friday: {
        day6OpenTimeTwo: this.cs.timeWithoutSeconds(openTime),
        day6CloseTimeTwo: this.cs.timeWithoutSeconds(closeTime)
      },
      saturday: {
        day7OpenTimeTwo: this.cs.timeWithoutSeconds(openTime),
        day7CloseTimeTwo: this.cs.timeWithoutSeconds(closeTime)
      }
    });
  }

  //Getor - To access the fields directly in the form in HTML
  get barName() {
    return this.profileForm.get("barName");
  }
  get companyName() {
    return this.profileForm.get("companyName");
  }
  get table_booking_amount() {
    return this.profileForm.get("table_booking_amount");
  }
  get orderCancellationTime() {
    return this.profileForm.get("orderCancellationTime");
  }
  get email() {
    return this.profileForm.get("email");
  }
  get wifi_name() {
    return this.profileForm.get("wifi_name");
  }
  get wifi_password() {
    return this.profileForm.get("wifi_password");
  }
  get phone() {
    return this.profileForm.get("phone");
  }
  get additionalPhones(): FormArray {
    return this.profileForm.get('additionalPhones') as FormArray;
  }
  get phone2() {
    return this.profileForm.get("phone2");
  }
  get cuisines() {
    return this.profileForm.get("cuisines");
  }
  get about() {
    return this.profileForm.get("about");
  }
  get timezone() {
    return this.profileForm.get("timezone");
  }
  get currency_id() {
    return this.profileForm.get("currency_id");
  }
  get address() {
    return this.profileForm.get("address");
  }
  get addressfull() {
    console.log('poiuii');
    
    return this.profileForm.get("addressfull");
  }
  get countryId() {
    return this.profileForm.get("countryId");
  }
  get stateId() {
    return this.profileForm.get("stateId");
  }
  get cityId() {
    return this.profileForm.get("cityId");
  }
  get areaId() {
    return this.profileForm.get("areaId");
  }
  get localArea() {
    return this.profileForm.get("localArea");
  }
  get lat() {
    return this.profileForm.get("lat");
  }
  get long() {
    return this.profileForm.get("long");
  }
  //Gator of Taxes Form
  get cgst() {
    return this.taxForm.get("cgst");
  }
  get gstNumber() {
    return this.taxForm.get("gstNumber");
  }
  get sgst() {
    return this.taxForm.get("sgst");
  }
  get vat() {
    return this.taxForm.get("vat");
  }
  get serviceCharge() {
    return this.taxForm.get("serviceCharge");
  }
  get serviceChargeTax() {
    return this.taxForm.get("serviceChargeTax");
  }
  get serviceChargeTax2() {
    return this.taxForm.get("serviceChargeTax2");
  }

  //Gator of timingForm Form
  get day1OpenTime() {
    return this.timingForm.get("day1OpenTime");
  }
  get day1CloseTime() {
    return this.timingForm.get("day1CloseTime");
  }
  get day2OpenTime() {
    return this.timingForm.get("day2OpenTime");
  }
  get day3OpenTime() {
    return this.timingForm.get("day3OpenTime");
  }


  get day1OpenTimeTwo() {
    return this.timingForm.get("day1OpenTimeTwo");
  }
  get day1CloseTimeTwo() {
    return this.timingForm.get("day1CloseTimeTwo");
  }
  get day2OpenTimeTwo() {
    return this.timingForm.get("day2OpenTimeTwo");
  }
  get day3OpenTimeTwo() {
    return this.timingForm.get("day3OpenTimeTwo");
  }

  // Gator of Bank Form
  get accountName() {
    return this.bankForm.get("accountName");
  }
  get accountNo() {
    return this.bankForm.get("accountNo");
  }
  get bankName() {
    return this.bankForm.get("bankName");
  }
  get onlinePaymentName() {
    return this.bankForm.get("onlinePaymentName");
  }
  get commission(){
    return this.bankForm.get("commission");
  }
  get bankBranch() {
    return this.bankForm.get("bankBranch");
  }
  get ifsc() {
    return this.bankForm.get("ifsc");
  }
  get cancelCheque() {
    return this.bankForm.get("cancelCheque");
  }

  // Gator of Kitchin Timing Form
  get foodEndTime() {
    return this.kitchenTimingForm.get("foodEndTime");
  }
  get foodMenu() {
    return this.kitchenTimingForm.get("foodMenu");
  }
  get drinkEndTime() {
    return this.kitchenTimingForm.get("drinkEndTime");
  }
  get drinkMenu() {
    return this.kitchenTimingForm.get("drinkMenu");
  }
  get mediaType() {
    return this.bannerForm.get("mediaType");
  }

  //Gator of Timing Form
  /*get opTime() {
    return this.timingForm.get("opTime");
  }*/

  //Gator of KYC Form
  get licenseTypeId() {
    return this.kycForm.get("licenseTypeId");
  }
  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        //this.latitude = position.coords.latitude;
        //this.longitude = position.coords.longitude;
        this.zoom = 10;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  markerDragEnd($event: MouseEvent) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }
  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: parseFloat(latitude), lng: parseFloat(longitude) } },
      (results, status) => {
        console.log(results);
        console.log(status);
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 10;
            this.customAddress = results[0].formatted_address;
            this.profileForm.controls["lat"].patchValue(latitude);
            this.profileForm.controls["long"].patchValue(longitude);
            this.profileForm.controls["address"].patchValue(this.customAddress);
          } else {
            // window.alert('No results found');
          }
        } else {
          //window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }

  // Funciton to mark banner as default
  markAsDefaultBanner() {
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;
    const postData = new FormData();
    postData.append("bannerId", this.bannerId);
    postData.append("barId", this.barId);
    postData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(postData, "updateBannerOrder").subscribe(response => {
      if (response.status === 1) {
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        this.myapp.isLoadingResults = false;
        this.ngOnInit();
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
  }

  //Function to go back to previous route
  goBack() {
    history.back();
    return false;
  }
  //Function to Active/Inactive records
  // changeStatus(id, value) {
  //   this.myapp.changeStatus("bnUsers", id, value);
  //   this.barStatus = value;
  //   this.myapp.showSuccessAlert = true;
  //   this.myapp.successMsg = 'Status updated successfully!';
  // }

  onKitchenTimingSubmit() {
    if (!this.timingForm.get("sunday").value.day1CloseTime) {
      this.myapp.showErrorAlert = true;
      this.myapp.errorMsg = "Please set the opening closing time first";
      return;
    }

    //Validate timing with closing time
    let foodTime = this.foodEndTime.value;
    let ftArray = foodTime.split(":");
    let updatedFtHour = this.cs.addHours(ftArray[0]);
    foodTime = updatedFtHour + ":" + ftArray[1];

    let drinkTime = this.drinkEndTime.value;
    let dtArray = drinkTime.split(":");
    let updatedDtHour = this.cs.addHours(dtArray[0]);
    drinkTime = updatedDtHour + ":" + dtArray[1];

    // Update the closing time to 24, 25, 26 in hours
    let closingTime = this.timingForm.get("sunday").value.day1CloseTime;
    let ctArray = closingTime.split(":");
    let updatedHour = this.cs.addHours(ctArray[0]);
    closingTime = updatedHour + ":" + ctArray[1];

    if (foodTime > closingTime || drinkTime > closingTime) {
      this.myapp.showErrorAlert = true;
      this.myapp.errorMsg =
        "Last order time should be less than or equal to closing time";
      return;
    }

    this.myapp.hideAlerts();
    this.imageSave = false;

    this.myapp.isLoadingResults = true;
    const formData = new FormData();
    formData.append("barId", this.barId);
    formData.append("foodEndTime", this.foodEndTime.value);
    this.foodMenuFiles.forEach((item) => {
      formData.append('foodMenu[]', item.file);
    });
    // formData.append("foodMenu", this.foodMenu.value);
    formData.append("drinkEndTime", this.drinkEndTime.value);
    // formData.append("drinkMenu", this.drinkMenu.value);
    this.drinkMenuFiles.forEach((itemDrink) => {
      formData.append('drinkMenu[]', itemDrink.file);
    });
    formData.append("token", localStorage.getItem("token"));

    //While EDIT
    if (this.ktData.length) {
      if (this.ktData[0].id) formData.append("foodPid", this.ktData[0].id);
      if (this.ktData[1].id) formData.append("drinkPid", this.ktData[1].id);
    }

    this.bs.callAPI(formData, "updateBarKitchenTiming").subscribe(response => {
      if (response.status === 1) {
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        this.myapp.isLoadingResults = false;
        this.foodMenuFiles = [];
        this.drinkMenuFiles = [];
        this.kitchenTimingForm.reset();
        this.kitchenTimingForm.patchValue({
          foodEndTime: "",
          foodMenu: "",
          drinkEndTime: "",
          drinkMenu: ""
        });

        this.foodFileErrorMessage = false;
        this.drinkFileErrorMessage = false;
        this.foodMenuLable = "Choose a file...";
        this.drinkMenuLable = "Choose a file...";
        this.foodMenuPath = null;
        this.drinkMenuPath = null;

        this.ngOnInit();
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
  }

  menuViewer(img) {
    this.menuImgPath = img;
  }
  loadBannerOption() {
    this.bannerCoverType = this.mediaType.value;
  }

  get generateCodeCancelTiming() {
    return this.cancelTiming.get("generateCodeCancelTiming");
  }
  get preOrderOfferCancelTiming() {
    return this.cancelTiming.get("preOrderOfferCancelTiming");
  }
  get preOrderCancelTiming() {
    return this.cancelTiming.get("preOrderCancelTiming");
  }
  get tableCancelTiming() {
    return this.cancelTiming.get("tableCancelTiming");
  }
  get eventCancelTiming() {
    return this.cancelTiming.get("eventCancelTiming");
  }
  changeGenerateCodeCancelTimmer(){
    this.generateCodeCancelTimingStatus = !this.generateCodeCancelTimingStatus;
  }
  changePreOrderOfferCancelTimmer(){
    this.preOrderOfferCancelTimingStatus = !this.preOrderOfferCancelTimingStatus;
  }
  changePreOrderCancelTimmer(){
    this.preOrderCancelTimingStatus = !this.preOrderCancelTimingStatus;
  }
  changeTableCancelTimmer(){
    this.tableBookingCancelTimingStatus = !this.tableBookingCancelTimingStatus;
  }
  changeEventCancelTimmer(){
    this.eventBookingCancelTimingStatus = !this.eventBookingCancelTimingStatus;
  }

  onCancelTimiingSubmit() {
    alert('pp');
    // Your submit logic here
  }
}
