import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";

@Component({
  selector: "app-view-patron",
  templateUrl: "./view-patron.component.html",
  styleUrls: ["./view-patron.component.css"]
})
export class ViewPatronComponent implements OnInit {
  //Variable Declration
  cancelForm: FormGroup;
  broadForm: FormGroup;
  cbForm: FormGroup;
  patronData = [];
  hideLoadMore = true;
  page = 1; //used for load more
  filterForm: FormGroup;
  showModal = true;
  cancelReason: any = null;
  cities: any[];
  sortedCities: any[];
  citiesSorted = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    // Admin routes validation
    this.cs.isAdminCustomerCareRoute();

    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      keyword: [""],
      status: [""],
      city: [localStorage.getItem("activeCity")]
    });

    //Cancel Form Model Declration with form validation for the fields
    this.cancelForm = this.fb.group({
      userId: ["", [Validators.required]],
      status: ["", [Validators.required]],
      reason: [
        "",
        [
          //Validators.required,
          Validators.minLength(3),
          Validators.maxLength(200)
        ]
      ]
    });

    //Broadcast Model
    this.broadForm = this.fb.group({
      messageType: ["", [Validators.required]],
      userId: ["", [Validators.required]],
      subject: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(200)
        ]
      ],
      message: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(500)
        ]
      ],
      emailMsg: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(500)
        ]
      ]
    });

    //Cashback Model
    this.cbForm = this.fb.group({
      userId: ["", [Validators.required]],
      amount: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9()]+$")
          //Validators.min(0)
        ]
      ]
    });

    // Load all cities
    this.cities = this.myapp.allCities;

    this.loadPatronItems();
  }

  //Load item defination
  loadPatronItems() {
    const userData = new FormData();
    userData.append("keyword", this.filterForm.get("keyword").value);
    userData.append("status", this.filterForm.get("status").value);
    userData.append("token", localStorage.getItem("token"));
    //userData.append("city", this.filterForm.get("city").value);

    this.bs.callAPI(userData, "patronList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.patronData = response.data;
        } else {
          this.patronData = [];
          // this.myapp.showErrorAlert = true;
          // this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const userData = new FormData();
    userData.append("page", pageNo);
    userData.append("keyword", this.filterForm.get("keyword").value);
    userData.append("status", this.filterForm.get("status").value);
    userData.append("token", localStorage.getItem("token"));
    //userData.append("city", this.filterForm.get("city").value);

    this.bs.callAPI(userData, "patronList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.patronData.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //On Keyword filter
  onKeyWordChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let status = this.filterForm.get("status").value;
    let city = this.filterForm.get("city").value;
    this.loadPatronItems();
  }

  onDropDownChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let status = this.filterForm.get("status").value;
    let city = this.filterForm.get("city").value;
    this.loadPatronItems();
  }

  // Function to filer Cities - for Bar
  filterByCity(keyCode) {
    // Search on Enter Key Press
    if (keyCode == 13) {
      this.onKeyWordChange();
      this.citiesSorted = false;
    } else {
      let keyword = this.filterForm.get("city").value;
      if (keyword.length > 0) {
        let output = [];
        this.cities.forEach(function(cities) {
          if (cities.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
            output.push(cities);
          }
        });
        if (output.length > 0) {
          this.sortedCities = output;
          this.citiesSorted = true;
        } else {
          this.sortedCities = [];
          this.citiesSorted = false;
        }
      } else {
        this.sortedCities = [];
        this.citiesSorted = false;
        this.onKeyWordChange();
      }
    }
  }

  // Function to pick city from suggestions
  fillTextBox(str) {
    this.filterForm.patchValue({
      city: str
    });
    this.sortedCities = [];
    this.citiesSorted = false;
    this.onKeyWordChange();
  }

  //Function to Active/Inactive records
  changeStatus(id, status) {
    this.showModal = true;
    this.cancelForm.patchValue({
      userId: id,
      status: status,
      token:localStorage.getItem("token")
    });
    if (status == 1) this.onCancelSubmit();
  }
  /*changeStatus(id, value, index) {
    this.myapp.changeStatus("bnUsers", id, value);

    //based on the index we can true/false the values in the loaded array
    if (value == 1) this.patronData[index].status = true;
    else if (value == 0) this.patronData[index].status = false;
  }*/

  // Function to inactive/active the user
  onCancelSubmit() {
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;
    this.bs
      .callAPI(JSON.stringify(this.cancelForm.value), "changeUserStatusNew")
      .subscribe(response => {
        if (response.status === 1) {
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.myapp.isLoadingResults = false;
          this.ngOnInit();

          // Hide the Modal
          this.showModal = false;
          let closeModal: HTMLElement = document.getElementsByClassName(
            "g-sidenav-pinned"
          )[0] as HTMLElement;
          closeModal.classList.remove("modal-open");
          let closeModalpadding: HTMLElement = document.getElementsByClassName(
            "g-sidenav-pinned"
          )[0] as HTMLElement;
          closeModalpadding.style.padding = "0px";
          let ModalBackdropDiv: HTMLElement = document.getElementsByClassName(
            "modal-backdrop"
          )[0] as HTMLElement;
          ModalBackdropDiv.parentNode.removeChild(ModalBackdropDiv);
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.myapp.isLoadingResults = false;
        }
      });
  }

  //Function to show the reason in modal
  loadCancelReason(reason) {
    this.cancelReason = reason;
  }

  //Function to grab user Id
  patchUserId(id, module: string) {
    this.showModal = true;
    if (module == "cashback") {
      this.cbForm.patchValue({
        userId: id
      });
    } else {
      this.broadForm.patchValue({
        userId: id
      });
    }
  }

  changeMessageType() {
    if (this.messageType.value == "1") {
      this.message.setValue("");
      this.emailMsg.setValue("Test");
    } else if (this.messageType.value == "2") {
      this.message.setValue("Test");
      this.emailMsg.setValue("");
    } else {
      this.message.setValue("");
      this.emailMsg.setValue("");
    }
  }

  //Broadcast message function
  onMessageSubmit() {
    this.myapp.hideAlerts();

    this.myapp.isLoadingResults = true;
    const formData = new FormData();
    formData.append("messageType", this.messageType.value);
    formData.append("userId", this.broadForm.get("userId").value);
    formData.append("subject", this.subject.value);
    formData.append("message", this.message.value);
    formData.append("emailMsg", this.emailMsg.value);
    formData.append("token", localStorage.getItem("token"));
    //Form submit by appi call
    this.bs.callAPI(formData, "sendBroadcastMessage").subscribe(response => {
      if (response.status === 1) {
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        this.myapp.isLoadingResults = false;
        this.broadForm.reset();
        this.broadForm.get("userId").setValue("");
        this.messageType.setValue("");

        // Hide the Modal
        this.showModal = false;
        let closeModal: HTMLElement = document.getElementsByClassName(
          "g-sidenav-pinned"
        )[0] as HTMLElement;
        closeModal.classList.remove("modal-open");
        let closeModalpadding: HTMLElement = document.getElementsByClassName(
          "g-sidenav-pinned"
        )[0] as HTMLElement;
        closeModalpadding.style.padding = "0px";
        let ModalBackdropDiv: HTMLElement = document.getElementsByClassName(
          "modal-backdrop"
        )[0] as HTMLElement;
        ModalBackdropDiv.parentNode.removeChild(ModalBackdropDiv);
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
  }

  //Cashback submit function
  onCashbackSubmit() {
    this.myapp.hideAlerts();

    //this.myapp.isLoadingResults = true;
    const formData = new FormData();
    formData.append("userId", this.cbForm.get("userId").value);
    formData.append("amount", this.amount.value);
    formData.append("token", localStorage.getItem("token"));
    //Form submit by appi call
    this.bs.callAPI(formData, "addPatronCashback").subscribe(response => {
      if (response.status === 1) {
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        this.myapp.isLoadingResults = false;
        this.ngOnInit();

        // Hide the Modal
        this.showModal = false;
        let closeModal: HTMLElement = document.getElementsByClassName(
          "g-sidenav-pinned"
        )[0] as HTMLElement;
        closeModal.classList.remove("modal-open");
        let closeModalpadding: HTMLElement = document.getElementsByClassName(
          "g-sidenav-pinned"
        )[0] as HTMLElement;
        closeModalpadding.style.padding = "0px";
        let ModalBackdropDiv: HTMLElement = document.getElementsByClassName(
          "modal-backdrop"
        )[0] as HTMLElement;
        ModalBackdropDiv.parentNode.removeChild(ModalBackdropDiv);
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
  }


  deleteCustomer(pID) {
    console.log(pID);
    
    // this.myapp.hideAlerts();

    //this.myapp.isLoadingResults = true;
    const formData = new FormData();
    formData.append("userId", pID);
    formData.append("token", localStorage.getItem("token"));

    // formData.append("amount", this.amount.value);
    // customer Delete by appi call
    this.bs.callAPI(formData, "deleteCustomer").subscribe(response => {
      //console.log(response);
      
      if (response.status === 1) {
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        this.myapp.isLoadingResults = false;
        this.ngOnInit();
      }
    });
  }

  // Getor - To access the fields directly in the form in HTML
  get reason() {
    return this.cancelForm.get("reason");
  }
  get messageType() {
    return this.broadForm.get("messageType");
  }
  get subject() {
    return this.broadForm.get("subject");
  }
  get message() {
    return this.broadForm.get("message");
  }
  get emailMsg() {
    return this.broadForm.get("emailMsg");
  }
  get amount() {
    return this.cbForm.get("amount");
  }
}
