import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";

import { title } from "process";
import { AbstractControl } from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../../service/bar.service";
import { CommonService } from "../../../service/common.service";
import { AppComponent } from "../../../app.component";
import { invalid } from "@angular/compiler/src/render3/view/util";
import { Time } from "@angular/common";
import { ConfirmDialogComponent } from "../../../confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material";


@Component({
  selector: "app-add-template",
  templateUrl: "./add-template.component.html",
  styleUrls: ["./add-template.component.css"]
})
export class AddTemplateComponent implements OnInit {
  templateForm: FormGroup;
  actionType = "add";
  pageTitle: string;
  dynamicRoute: any = "";
  FileErrorMessageTemplate = false;
  templatePrePath = null;
  templatePath = this.cs.tempPath();
  templateList = [];
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent,
    public dialog: MatDialog

  ) {}

  ngOnInit() {
    // Admin routes validation
    this.cs.isAdminRoute();
    this.templateForm = this.fb.group({
      id: [""],
      templateName: [""], //[Validators.required]
      mediaName: ["", [Validators.required]]
    });
    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.actionType = "edit";
        this.pageTitle = "Edit Template";
        this.dynamicRoute = "editTemplate";
        this.loadTemplateData(id);
      } else {
        this.actionType = "add";
        this.pageTitle = "Add Template";
        this.dynamicRoute = "addTemplate";
      }
    });
    this.loadTemplateList();
  }
  onFileSelect(event) {
    //form
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      var fileMimes = file.type.split("/");
      var fileType = fileMimes[0];
      if (fileType === "image") {
        this.FileErrorMessageTemplate = false;
        this.templateForm.get("mediaName").setValue(file);
        //Preveiw of the image only on images
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = _event => {
          this.templatePrePath = reader.result;
        };
        return;
      } else {
        this.FileErrorMessageTemplate = true;
      }
    }
  }

  onFormSubmit() {
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;
    const bannerData = new FormData();
    bannerData.append("id", this.templateForm.get("id").value);
    bannerData.append(
      "templateName",
      this.templateForm.get("templateName").value
    );
    bannerData.append("mediaName", this.templateForm.get("mediaName").value);
    //console.log(bannerData);
    bannerData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(bannerData, "addTemplate").subscribe(response => {
      if (response.status === 1) {
        if (this.actionType == "edit") {
          this.templatePrePath = null;
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.myapp.isLoadingResults = false;
          this.router.navigate(["/add-template"]);
        } else {
          this.templatePrePath = null;
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.myapp.isLoadingResults = false;
          this.templateForm.reset();
          //this.templatePath = null;
          //Add the latest added image in Array
          this.templateList.unshift(response.data);
        }
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
  }
  //Load template data defination
  loadTemplateList() {
    this.bs.callAPI("", "getTemplateList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.templateList = response.data;
        } else {
          this.templateList = [];
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  //load template data for edit
  loadTemplateData(id) {
    const param = new FormData();
    param.append("id", id);
    param.append("token", localStorage.getItem("token"));
    this.bs.callAPI(param, "getTemplateDetails").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.templateForm.patchValue({
            id: response.data.id,
            templateName: response.data.templateName,
            mediaName: ""
          });
          this.templatePrePath = this.templatePath + response.data.mediaName;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

   //Media Delete Function
   deleteMedia(type, id, index) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want to delete this?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.myapp.hideAlerts();
        const mediaData = new FormData();
        mediaData.append("id", id);
        mediaData.append("type", type);
        mediaData.append("token", localStorage.getItem("token"));
        console.log(mediaData);
        
        this.bs.callAPI(mediaData, "deleteBarMedia").subscribe(response => {
          if (response.status === 1) {
            this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            //Delete the key from array
            this.templateList.splice(index, 1);
          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        });
      }
    });
    return false;
  }
  get templateName() {
    return this.templateForm.get("templateName");
  }
  get email() {
    return this.templateForm.get("mediaName");
  }
}
