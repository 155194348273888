import { Component, OnInit ,ViewChild,
  ElementRef,
  NgZone,Renderer2} from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";

import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";


@Component({
  selector: "app-view-bar",
  templateUrl: "./view-bar.component.html",
  styleUrls: ["./view-bar.component.css"]
})
export class ViewBarComponent implements OnInit {
  //Variable Declration
  filterForm: FormGroup;
  cancelForm: FormGroup;
  broadForm: FormGroup;
  cloneForm: FormGroup;
  barData = [];
  allCities = [];
  dropdownSettings = {};
  selectedItems = [];
  // dropdownSettings = {};
  selectedIds: number[] = [];
  // tablesIds =[];
  tablesIds: number[] = [];
  hideLoadMore = true;
  activeBarId: any = 0;
  page = 1; //used for load more
  showModal = true;
  cancelReason: any = null;
  cities: any[];
  sortedCities: any[];
  citiesSorted = false;
  countries: any[];
  sortedCountries: any[];
  countriesSorted = false;
  cityNew: number = 0;
  resturantId = null;
  restaurantPassword = null;
  restaurantDetailsActive =false;
  @ViewChild('resetModal', { static: false }) resetModal: ElementRef;

  // showModal: boolean = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent,
    private renderer: Renderer2,
    public dialog: MatDialog

  ) {}

  ngOnInit() {
    //this.activeBarId = localStorage.getItem("activeBarId");
   
    //Define the Filter Form Model
    this.filterForm = this.fb.group({
      keyword: [""],
      status: [""],
      city: [localStorage.getItem("activeCity")],
      country: [localStorage.getItem("activeCountryId")]
    });

    //Cancel Form Model Declration with form validation for the fields
    this.cancelForm = this.fb.group({
      userId: ["", [Validators.required]],
      status: ["", [Validators.required]],
      reason: [
        "",
        [
          //Validators.required,
          Validators.minLength(3),
          Validators.maxLength(200)
        ]
      ]
    });

    //Broadcast Model
    this.broadForm = this.fb.group({
      messageType: ["", [Validators.required]],
      userId: ["", [Validators.required]],
      subject: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(200)
        ]
      ],
      message: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(500)
        ]
      ],
      emailMsg: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(500)
        ]
      ]
    });


    //clone Model
    this.cloneForm = this.fb.group({
      cityId: [null],
      userId: ["", [Validators.required]],
      restaurantName: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(200)
        ]
      ],
      localArea: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(500)
        ]
      ]
    });

    // Load all cities
    this.cities = this.myapp.allCities;
    
    this.countries = this.myapp.allCountries;

    this.loadBarList();
    this.loadCities();
    this.dropdownSettings= {
      singleSelection: true,
      idField: 'city_id',
      textField: 'city_name',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  //Load item defination
  loadBarList() {
    const userData = new FormData();
    userData.append("keyword", this.filterForm.get("keyword").value);
    userData.append("status", this.filterForm.get("status").value);
    userData.append("city", this.filterForm.get("city").value);
    userData.append("countryId", this.filterForm.get("country").value);
    userData.append("roleId", window.localStorage.getItem("roleId"));
    userData.append("userId", window.localStorage.getItem("userId"));
    userData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(userData, "barList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.barData = response.data;
        } else {
          this.barData = [];
          // this.myapp.showErrorAlert = true;
          // this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Load cities, on States change event
  loadCities() {
    this.myapp.hideAlerts();
    
      const formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      this.bs.callAPI(formData, "getCities").subscribe(
        //this.myapp.isLoadingResults = false;
        (response: any) => {
          // console.log(response);
          if (response.status == 1) {           
            this.allCities = response.data;
            this.dropdownSettings = {
              singleSelection: true,
              idField: 'item_id',
              textField: 'item_text',
              
              itemsShowLimit: 3,
              allowSearchFilter: true
            };

          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        },
        error => {
          console.log(error);
        }
      );
  }


  //Load More Data Function
  loadMoreData(pageNo) {
    this.myapp.isLoadingResults = true;
    this.myapp.hideAlerts();
    const userData = new FormData();
    userData.append("page", pageNo);
    userData.append("keyword", this.filterForm.get("keyword").value);
    userData.append("status", this.filterForm.get("status").value);
    userData.append("city", this.filterForm.get("city").value);
    userData.append("counrty", this.filterForm.get("country").value);
    userData.append("roleId", window.localStorage.getItem("roleId"));
    userData.append("userId", window.localStorage.getItem("userId"));
    userData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(userData, "barList").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.page++; //Increment of the page
          this.myapp.isLoadingResults = false;

          for (let i = 0; i < response.data.length; i++) {
            this.barData.push(response.data[i]);
          }

          //Hide the load more button if reaches ot limit
          if (response.limit != response.data.length) this.hideLoadMore = false;
        } else {
          this.myapp.isLoadingResults = false;
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.hideLoadMore = false;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  onItemSelect(event: any) {
    
    console.log(event.item_id);
    this.cityNew =event.item_id;
    console.log('lkkkk');
    console.log(this.cityNew);  
  }
  
  logSelectedItems(id: number) {
    this.selectedIds.push(id);
    console.log(this.selectedIds.join(','));
  }

  //On Keyword filter
  onKeyWordChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let status = this.filterForm.get("status").value;
    let city = this.filterForm.get("city").value;
    let country = this.filterForm.get("country").value;
    this.loadBarList();
  }
  onDropDownChange() {
    this.hideLoadMore = true;
    this.page = 1;
    let keyword = this.filterForm.get("keyword").value;
    let status = this.filterForm.get("status").value;
    let city = this.filterForm.get("city").value;
    let country = this.filterForm.get("country").value;
    this.loadBarList();
  }

  // Function to filer Cities - for Bar
  filterByCity(keyCode) {
    // Search on Enter Key Press
    if (keyCode == 13) {
      this.onKeyWordChange();
      this.citiesSorted = false;
    } else {
      let keyword = this.filterForm.get("city").value;
      if (keyword.length > 0) {
        let output = [];
        this.cities.forEach(function(cities) {
          if (cities.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
            output.push(cities);
          }
        });
        if (output.length > 0) {
          this.sortedCities = output;
          this.citiesSorted = true;
        } else {
          this.sortedCities = [];
          this.citiesSorted = false;
          // window.localStorage.setItem("activeCity", "");
        }
      } else {
        this.sortedCities = [];
        this.citiesSorted = false;
        // window.localStorage.setItem("activeCity", "");
        this.onKeyWordChange();
      }
    }
  }

  // Function to pick city from suggestions
  fillTextBox(str) {
    this.filterForm.patchValue({
      city: str
    });
    this.sortedCities = [];
    this.citiesSorted = false;
    // window.localStorage.setItem("activeCity", str);
    this.onKeyWordChange();
  }


  // Function to filer Cities - for Bar
  filterByCountry(keyCode) {
    // Search on Enter Key Press
    if (keyCode == 13) {
      this.onKeyWordChange();
      this.countriesSorted = false;
    } else {
      let keyword = this.filterForm.get("country").value;
      if (keyword.length > 0) {
        let output = [];
        this.countries.forEach(function(countries) {
          if (countries.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
            output.push(countries);
          }
        });
        if (output.length > 0) {
          this.sortedCountries = output;
          this.countriesSorted = true;
        } else {
          this.sortedCountries = [];
          this.countriesSorted = false;
          // window.localStorage.setItem("activeCountryId", "");
        }
      } else {
        this.sortedCountries = [];
        this.countriesSorted = false;
        // window.localStorage.setItem("activeCountryId", "");
        this.onKeyWordChange();
      }
    }
  }

  // Function to pick city from suggestions
  fillTextBoxcountry(str) {
    this.filterForm.patchValue({
      country: str
    });
    this.sortedCountries = [];
    this.countriesSorted = false;
    // window.localStorage.setItem("activeCountryId", str);
    this.onKeyWordChange();
  }

  // Function to Active/Inactive records
  changeStatus(id, status) {
    this.showModal = true;
    this.cancelForm.patchValue({
      userId: id,
      status: status
    });
    if (status == 1) {
      this.onCancelSubmit();
    }else if( status == 0){
      this.onCancelSubmit();
    }
  }
  changeStatusNew(id, status) {
    this.showModal = true;
    this.cancelForm.patchValue({
      userId: id,
      status: status
    });
    if (status == 1) {
      this.onCancelSubmitNew();
    }else if( status == 0){
      this.onCancelSubmitNew();
    }
  }
  deleteRestaurant(id){
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: "Are you sure you want to delete this?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      console.log(result);
      if (result == true) {
        const formData = new FormData();   
        formData.append("id", id);
        
        this.bs
          .callAPI(formData, "restaurantDelete")
          .subscribe(response => {
            console.log(response);
            
            if (response.status === 1) {
              this.myapp.showSuccessAlert = true;
              this.myapp.successMsg = response.message;
              this.myapp.isLoadingResults = false;
              this.ngOnInit();
            }
          })
          
      }
    })
    
  }
  //resetPassword
  resetPassword(id) {
    const formData = new FormData();   
    formData.append("id", id);
    
    this.bs
      .callAPI(formData, "adminPasswordReset")
      .subscribe(response => {
        console.log(response);
        
        if (response.status === 1) {
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.myapp.isLoadingResults = false;
          // this.ngOnInit();
          this.resturantId = response.data.restaurantId;
          this.restaurantPassword = response.data.password;
          
          if (this.resetModal) {
            // this.newLoad = true;
            this.showModal = true;
            this.renderer.addClass(this.resetModal.nativeElement, 'show');
            this.renderer.setStyle(this.resetModal.nativeElement, 'display', 'block');
            this.renderer.setStyle(this.resetModal.nativeElement, 'background-color', 'rgba(0,0,0,0.5)');
          } else {
            console.error("Modal element not found.");
          }
          
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.myapp.isLoadingResults = false;
        }
      });
   
  }

  closeModal() {
    this.renderer.removeClass(this.resetModal.nativeElement, 'show');
    this.renderer.setStyle(this.resetModal.nativeElement, 'display', 'none');
    this.renderer.setStyle(this.resetModal.nativeElement, 'background-color', 'rgba(0,0,0,0)');
    // this.restaurantPassword ='';
    // this.resturantId ='';
    this.loadBarList();
  }
  copyToClipboard() {
    const textToCopy = `Restaurant ID: ${this.resturantId}\nPassword: ${this.restaurantPassword}`;
    navigator.clipboard.writeText(textToCopy).then(() => {
      this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = "Restaurant Details copied to successfully";
          
         
          
    }).catch(err => {
      // console.error('Failed to copy text: ', err);
    });
  }
  

  /*changeStatus(id, value, index) {
    this.myapp.changeStatus("bnUsers", id, value);

    //based on the index we can true/false the values in the loaded array
    if (value == 1) this.barData[index].status = true;
    else if (value == 0) this.barData[index].status = false;
  }*/

  // Function to inactive/active the user
  onCancelSubmit() {
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;
  
   const param = new FormData();
   param.append("userId", this.cancelForm.value['userId']);
   param.append("status", this.cancelForm.value['status']);
   param.append("reason", this.cancelForm.value['reason']);
    // this.bs.callAPI(JSON.stringify(this.cancelForm.value), "changeUserStatus").subscribe(
    this.bs
      .callAPI(JSON.stringify(this.cancelForm.value), "changeUserStatusNew")
      .subscribe(
        response => {
        // console.log(response);
        
        if (response.status === 1) {
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.myapp.isLoadingResults = false;
          this.ngOnInit();

          // Hide the Modal
          this.showModal = false;
          let closeModal: HTMLElement = document.getElementsByClassName(
            "g-sidenav-pinned"
          )[0] as HTMLElement;
          closeModal.classList.remove("modal-open");
          let closeModalpadding: HTMLElement = document.getElementsByClassName(
            "g-sidenav-pinned"
          )[0] as HTMLElement;
          closeModalpadding.style.padding = "0px";
          let ModalBackdropDiv: HTMLElement = document.getElementsByClassName(
            "modal-backdrop"
          )[0] as HTMLElement;
          ModalBackdropDiv.parentNode.removeChild(ModalBackdropDiv);
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.myapp.isLoadingResults = false;
        }
      });
  }

  onCancelSubmitNew() {
    this.myapp.hideAlerts();
    this.myapp.isLoadingResults = true;
  
   const param = new FormData();
   param.append("userId", this.cancelForm.value['userId']);
   param.append("status", this.cancelForm.value['status']);
   param.append("reason", this.cancelForm.value['reason']);
    // this.bs.callAPI(JSON.stringify(this.cancelForm.value), "changeUserStatus").subscribe(
    this.bs
      .callAPI(JSON.stringify(this.cancelForm.value), "changeUserStatusNewUpdate")
      .subscribe(
        response => {
        // console.log(response);
        
        if (response.status === 1) {
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
          this.myapp.isLoadingResults = false;
          this.ngOnInit();

          // Hide the Modal
          this.showModal = false;
          let closeModal: HTMLElement = document.getElementsByClassName(
            "g-sidenav-pinned"
          )[0] as HTMLElement;
          closeModal.classList.remove("modal-open");
          let closeModalpadding: HTMLElement = document.getElementsByClassName(
            "g-sidenav-pinned"
          )[0] as HTMLElement;
          closeModalpadding.style.padding = "0px";
          let ModalBackdropDiv: HTMLElement = document.getElementsByClassName(
            "modal-backdrop"
          )[0] as HTMLElement;
          ModalBackdropDiv.parentNode.removeChild(ModalBackdropDiv);
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
          this.myapp.isLoadingResults = false;
        }
      });
  }

  //Function to show the reason in modal
  loadCancelReason(reason) {
    this.cancelReason = reason;
  }

  //Function to grab user Id
  setMessageUserId(id) {
    this.showModal = true;
    this.broadForm.patchValue({
      userId: id
    });
  }

   //Function to grab user Id
   setCloneUserId(id) {
    this.showModal = true;
    this.cloneForm.patchValue({
      userId: id
    });
  }

  changeMessageType() {
    if (this.messageType.value == "1") {
      this.message.setValue("");
      this.emailMsg.setValue("Test");
    } else if (this.messageType.value == "2") {
      this.message.setValue("Test");
      this.emailMsg.setValue("");
    } else {
      this.message.setValue("");
      this.emailMsg.setValue("");
    }
  }

  //Broadcast message function
  onCloneSubmit() {
    this.myapp.hideAlerts();

    this.myapp.isLoadingResults = true;
    console.log(this.cityNew);
    
    
    const formData = new FormData();
    
    formData.append("cityId", this.cityNew.toString());
    formData.append("barId", this.cloneForm.get("userId").value);
    formData.append("barName", this.restaurantName.value);
    formData.append("localArea", this.localArea.value);
    formData.append("token", localStorage.getItem("token"));
          // console.log(formData);
          
    //Form submit by appi call
    this.bs.callAPI(formData, "cloneRestaurant").subscribe(response => {
      if (response.status === 1) {
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        this.myapp.isLoadingResults = false;
        this.cloneForm.reset();
        this.cloneForm.get("userId").setValue("");
        this.resturantId =response.data.userName;
        this.restaurantPassword =response.data.password;
        this.restaurantDetailsActive = true;

      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
  }
 /* copyData() {
    // Create a temporary textarea element
    const textarea = document.createElement('textarea');
    // Set the value of the textarea to the text you want to copy
    textarea.value = 'Restaurant ID - '+this.resturantId+'\nRestaurant Password - '+this.restaurantPassword;

    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    // this.showModal = false;
    //     let closeModal: HTMLElement = document.getElementsByClassName(
    //       "g-sidenav-pinned"
    //     )[0] as HTMLElement;
    //     closeModal.classList.remove("modal-open");
    //     let closeModalpadding: HTMLElement = document.getElementsByClassName(
    //       "g-sidenav-pinned"
    //     )[0] as HTMLElement;
    //     closeModalpadding.style.padding = "0px";
    //     let ModalBackdropDiv: HTMLElement = document.getElementsByClassName(
    //       "modal-backdrop"
    //     )[0] as HTMLElement;
    //     ModalBackdropDiv.parentNode.removeChild(ModalBackdropDiv);
  } */

  //Broadcast message function
  onMessageSubmit() {
    this.myapp.hideAlerts();

    this.myapp.isLoadingResults = true;
    const formData = new FormData();
    formData.append("messageType", this.messageType.value);
    formData.append("userId", this.broadForm.get("userId").value);
    formData.append("subject", this.subject.value);
    formData.append("message", this.message.value);
    formData.append("emailMsg", this.emailMsg.value);
    formData.append("token", localStorage.getItem("token"));

    //Form submit by appi call
    this.bs.callAPI(formData, "sendBroadcastMessage").subscribe(response => {
      if (response.status === 1) {
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        this.myapp.isLoadingResults = false;
        this.broadForm.reset();
        this.broadForm.get("userId").setValue("");
        this.messageType.setValue("");

        // Hide the Modal
        this.showModal = false;
        let closeModal: HTMLElement = document.getElementsByClassName(
          "g-sidenav-pinned"
        )[0] as HTMLElement;
        closeModal.classList.remove("modal-open");
        let closeModalpadding: HTMLElement = document.getElementsByClassName(
          "g-sidenav-pinned"
        )[0] as HTMLElement;
        closeModalpadding.style.padding = "0px";
        let ModalBackdropDiv: HTMLElement = document.getElementsByClassName(
          "modal-backdrop"
        )[0] as HTMLElement;
        ModalBackdropDiv.parentNode.removeChild(ModalBackdropDiv);
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
  }

  //Clone section 
  get restaurantName() {
    return this.cloneForm.get("restaurantName");
  }
  get cityId() {
    return this.cloneForm.get("cityId");
  }
  get localArea() {
    return this.cloneForm.get("localArea");
  }
  // Getor - To access the fields directly in the form in HTML
  get reason() {
    return this.cancelForm.get("reason");
  }

  get messageType() {
    return this.broadForm.get("messageType");
  }
  get subject() {
    return this.broadForm.get("subject");
  }
  get message() {
    return this.broadForm.get("message");
  }
  get emailMsg() {
    return this.broadForm.get("emailMsg");
  }

  // Function to active a Bar for the panel
  /*activateBar(barId) {
    window.localStorage.setItem("barId", barId);
    window.localStorage.setItem("activeBarId", barId);
    this.activeBarId = barId;

    //Redirect to Bar Profile
    this.router.navigate(["/bar-profile"]);
  }*/
}
