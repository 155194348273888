import { Component, OnInit ,ViewChild,
  ElementRef,
  NgZone,Renderer2} from "@angular/core";import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";


@Component({
  selector: 'app-my-multipal-resturant',
  templateUrl: './my-multipal-resturant.component.html',
  styleUrls: ['./my-multipal-resturant.component.css']
})
export class MyMultipalResturantComponent implements OnInit {
  offerData = [];
  filterForm: FormGroup;
  showModal = true;
  resturantId = null;
  restaurantPassword = null;
  restaurantDetailsActive =false;
  @ViewChild('resetModal', { static: false }) resetModal: ElementRef;

  // constructor() { }
//Injecting the Services
constructor(
  public dialog: MatDialog,
  private fb: FormBuilder,
  private router: Router,
  private route: ActivatedRoute,
  private bs: BarService,
  private cs: CommonService,
  public myapp: AppComponent,
  private renderer: Renderer2,
) {}
  ngOnInit() {
    this.filterForm = this.fb.group({
      keyword: [""],
      
    });
    this.loadHappyHours(''); //three empty arguments, bcz this function is using in filters aslo

    
  }
//Load Discounts defination
loadHappyHours(keyword) {
  const barData = new FormData();
  barData.append("subBarIds", localStorage.getItem("subBarIds"));
  barData.append("keyword", this.filterForm.get("keyword").value);
  // barData.append("barId", localStorage.getItem("barId"));
  barData.append("token", localStorage.getItem("token"));

  this.bs.callAPI(barData, "restaurantSubData").subscribe(
    (response: any) => {
      if (response.status == 1) {
        this.offerData = response.data;
      } else {
        this.offerData = [];
        //this.myapp.showErrorAlert = true;
        //this.myapp.errorMsg = response.message;
      }
    },
    error => {
      console.log(error);
    }
  );
}
 //resetPassword
 resetPassword(id) {
  const formData = new FormData();   
  formData.append("id", id);
  
  this.bs
    .callAPI(formData, "adminPasswordReset")
    .subscribe(response => {
      console.log(response);
      
      if (response.status === 1) {
        this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = response.message;
        this.myapp.isLoadingResults = false;
        // this.ngOnInit();
        this.resturantId = response.data.restaurantId;
        this.restaurantPassword = response.data.password;
        
        if (this.resetModal) {
          // this.newLoad = true;
          this.showModal = true;
          this.renderer.addClass(this.resetModal.nativeElement, 'show');
          this.renderer.setStyle(this.resetModal.nativeElement, 'display', 'block');
          this.renderer.setStyle(this.resetModal.nativeElement, 'background-color', 'rgba(0,0,0,0.5)');
        } else {
          console.error("Modal element not found.");
        }
        
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
 
}

closeModal() {
  this.renderer.removeClass(this.resetModal.nativeElement, 'show');
  this.renderer.setStyle(this.resetModal.nativeElement, 'display', 'none');
  this.renderer.setStyle(this.resetModal.nativeElement, 'background-color', 'rgba(0,0,0,0)');
  // this.restaurantPassword ='';
  // this.resturantId ='';
  this.loadHappyHours('');
}
 //On Keyword filter
 onKeyWordChange() {
  // this.hideLoadMore = true;
  // this.page = 1;
  let keyword = this.filterForm.get("keyword").value;
  // let appliedOnDay = this.filterForm.get("appliedOnDay").value;
  // let appliedOn = this.filterForm.get("appliedOn").value;
  // let status = this.filterForm.get("status").value;
  this.loadHappyHours(keyword);
}
changeStatus(id){
  window.localStorage.setItem("barId", id);
  window.location.reload();
}


copyToClipboard() {
  const textToCopy = `Restaurant ID: ${this.resturantId}\nPassword: ${this.restaurantPassword}`;
  navigator.clipboard.writeText(textToCopy).then(() => {
    this.myapp.showSuccessAlert = true;
        this.myapp.successMsg = "Restaurant Details copied to successfully";
        
       
        
  }).catch(err => {
    // console.error('Failed to copy text: ', err);
  });
}
}
