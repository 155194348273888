import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../service/bar.service";
import { CommonService } from "../service/common.service";
import { AppComponent } from "../app.component";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";

@Component({
  selector: 'app-dashboard-master-bar',
  templateUrl: './dashboard-master-bar.component.html',
  styleUrls: ['./dashboard-master-bar.component.css']
})
export class DashboardMasterBarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
