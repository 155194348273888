import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../service/bar.service";
import { CommonService } from "../service/common.service";
import { AppComponent } from "../app.component";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"]
})
export class DashboardComponent implements OnInit {
  //Variable Declration
  filterForm: FormGroup;

  currencySymbol = localStorage.getItem("currency");
  tableVisits = [];
  subRestaurantLists = [];
  storedRestaurantId : any = window.localStorage.getItem("barId");
  standingVisits = [];
  otherData: any = [];
  userPicPath = this.cs.userProfilePic();
  dayOnOffStatus = true;
  openingStatus = true;
  date = new Date();
  now: number;
  notifyOrderList: any = [];
  disableClick = false;
  barStatus: any;
  barUserType = null;

  //Injecting the Services
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent,
    public dialog: MatDialog
  ) { }

  ngOnInit() {

    this.barUserType =localStorage.getItem("userType");
    // alert(this.storedRestaurantId);
    // console.log(localStorage.getItem("subBarIds"));
    this.filterForm = this.fb.group({
     
      appliedOn: [""],
     
    });
    //Access the Paramter from URL, when code is avail
    this.route.paramMap.subscribe((params: ParamMap) => {
      let str = params.get("str");
      if (str === "code") {
        this.router.navigate(["/dashboard"]);
      }
    });

    this.loadBarSubRestaurants();
    const storedRestaurantId = window.localStorage.getItem("barId");

    // If it exists, set it as the value for appliedOn
    if (storedRestaurantId) {
      this.filterForm.get('appliedOn').setValue(storedRestaurantId);
    }
    //Call the function
    this.loadVisits("");

    // Used to show the clock time on Dashboard
    setInterval(() => {
      this.now = Date.now();
    }, 1);

    setInterval(() => {
      this.loadVisits("");
    }, 3000);

    // If super admin try to reach here, then redirect to Admin Dashboard
    if (window.localStorage.getItem("userType") == "A")
      this.router.navigate(["/admin-dashboard"]);
    //check notify order list.
    if (localStorage.hasOwnProperty("barNotifyList")) {
      let barNotifyList = JSON.parse(localStorage.getItem("barNotifyList"));
      this.notifyOrderList = barNotifyList.notifyOrderList;
    }
    //if (this.myapp.newNotification) this.ngOnInit();
  }

  loadBarSubRestaurants() {
    const barData = new FormData();
    barData.append("subBarIds", localStorage.getItem("subBarIds"));
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "restaurantSubData").subscribe(
      (response: any) => {
        // console.log(response);
        // console.log('dddsss');
        
        if (response.status == 1) {
          this.subRestaurantLists =response.data;
          // console.log(this.subRestaurantLists);
          // console.log('ppp');
          
        } else {
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  //Load Visit function definiation
  loadVisits(keyword) {
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "getBarDashboardData").subscribe(
      (response: any) => {
        console.log(response);
        
        if (response.status == 1) {
          this.tableVisits = response.data.tableVisits;
          this.standingVisits = response.data.standingVisits;
          this.otherData = response.data.other;
          this.openingStatus = response.data.barInfo["openingStatus"];
          this.dayOnOffStatus = response.data.barInfo["dayOnOffStatus"];
          this.barStatus = response.data.barInfo["barStatus"];
          //console.log("visits:" + this.tableVisits);
          //console.log("openingStatus:" + this.openingStatus);
          //update tableVisites array for set notify status
          for (let i = 0; i < response.data.tableVisits.length; i++) {
            if (
              this.notifyOrderList.length > 0 &&
              this.notifyOrderList.indexOf(
                response.data.tableVisits[i].uniqueId
              ) > 0
            ) {
              this.tableVisits[i].notifyStatus = 1;
            } else {
              this.tableVisits[i].notifyStatus = 0;
            }
            /*console.log(
              "Unique id is:" + response.data.tableVisits[i].uniqueId
            );*/
          }
          //update standingVisits array for set notify status
          for (let i = 0; i < response.data.standingVisits.length; i++) {
            if (
              this.notifyOrderList.length > 0 &&
              this.notifyOrderList.indexOf(
                response.data.standingVisits[i].uniqueId
              ) > 0
            ) {
              this.standingVisits[i].notifyStatus = 1;
            } else {
              this.standingVisits[i].notifyStatus = 0;
            }
            /*console.log(
              "Unique id is:" + response.data.standingVisits[i].uniqueId
            );*/
          }
        } else {
          //this.myapp.showErrorAlert = true;
          //this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  onDropDownChange() {
    window.localStorage.setItem("barId", this.filterForm.get("appliedOn").value);
    window.location.reload(); 
  }
  //Function to change Visiblity Stauts on/off
  changeOpeningStatus() {
    //console.log("openingStatus:" + this.openingStatus);
    //console.log("dayOnOffStatus:" + this.dayOnOffStatus);
    if (this.dayOnOffStatus) {
      let alertMsg = "";
      let value = 0;
      if (this.openingStatus) {
        value = 1;
        alertMsg = "Are you sure you want to be visible on Patron App?";
      } else {
        alertMsg = "Are you sure you don't want to be visible on Patron App?";
      }

      let dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          message: alertMsg
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        //Result is coming from the buttons on the popup
        if (result == true) {
          this.myapp.changeStatus(
            "bnBars",
            localStorage.getItem("barId"),
            value
          );
        } else {
          if (this.openingStatus) this.openingStatus = false;
          else this.openingStatus = true;
        }
      });
    } else {
      this.myapp.showErrorAlert = true;
      this.myapp.errorMsg = "Business day should be turn ON to perform this";
      this.openingStatus = false;
      this.disableClick = true;
    }
  }

  //Function to Bar On/off daily basis
  changeDayStatus() {
    //console.log("dayOnOffStatus:" + this.dayOnOffStatus);
    //console.log("openingStatus:" + this.openingStatus);
    let value: any = 0;
    let alertMsg = "";
    if (this.dayOnOffStatus) {
      value = 1;
      this.openingStatus = true;
      alertMsg = "Are you sure you want to start the business day?";
    } else {
      this.openingStatus = false;
      this.disableClick = true;
      alertMsg = "Are you sure you want to close the business day?"; // You can not turn it ON it today.
    }
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: alertMsg
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //Result is coming from the buttons on the popup
      if (result == true) {
        this.myapp.hideAlerts();
        this.myapp.isLoadingResults = true;

        const barData = new FormData();
        barData.append("barId", localStorage.getItem("barId"));
        barData.append("dayOnOffStatus", value);
        barData.append("token", localStorage.getItem("token"));

        this.bs.callAPI(barData, "sendDailyReport").subscribe(
          (response: any) => {
            if (response.status == 1) {
              //console.log(response);
              this.myapp.showSuccessAlert = true;
              this.myapp.successMsg = response.message;
              this.myapp.isLoadingResults = false;
            } else {
              this.myapp.showErrorAlert = true;
              this.myapp.errorMsg = response.message;
              this.myapp.isLoadingResults = false;
              this.dayOnOffStatus = true;
              //this.openingStatus = true;

              //this.dayOnOffStatus = false;
              //this.openingStatus = false;
            }
          },
          error => {
            console.log(error);
          }
        );
      } else {
        this.disableClick = false;
        if (this.dayOnOffStatus) this.dayOnOffStatus = false;
        else this.dayOnOffStatus = true;
        if (this.openingStatus) this.openingStatus = false;
        else this.openingStatus = true;
      }
    });
  }
}
