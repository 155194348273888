// import { Component, OnInit } from "@angular/core";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone
} from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { MapsAPILoader, MouseEvent } from "@agm/core";
import { title } from "process";
import { AbstractControl } from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { invalid } from "@angular/compiler/src/render3/view/util";
import { Time } from "@angular/common";
import * as moment from "moment";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-add-special-event",
  templateUrl: "./add-special-event.component.html",
  styleUrls: ["./add-special-event.component.css"]
  
})

export class AddSpecialEventComponent implements OnInit {
  //Variable declration
  
  isEventPriceEnable = 0;
  specialeventForm: FormGroup;
  TimeErrorMessage = false;
  DateErrorMessage = false;
  DiscountErrorMessage = false;
  DiscountMaxErrorMessage = false;
  PublishErrorMessage = false;
  viewDetail = false;
  published: Date;
  expire: Date;
  finalValue: any;
  minDate = new Date();
  FileErrorMessageLog = false;
  eventImagePath = null;
  //startTime: any;
  //endTime: any;
  eventSetupFeeList = [];
  eventFee: any;
  showPublishBtn = true;
  showCancelBtn = false;
  base64Img: any = "";
  croppedImage: any = "";
  imageChangedEvent: any = "";
  eventImageSave: any = "";
  imageSave = false;
  actionType = "add";
  pageTitle: string;
  editDuration: boolean;
  todayDay: any;
  editPublishOn: boolean;
  MinuteErrorMessage = false;
  isFree =true;
  isFreeValue: any = "";
  barUserType = null;
  latitude: number;
  longitude: number;
  isMapShow: boolean = true;
  zoom: number;
  customAddress: string;
  // ActiveRestaurantList : [];
  ActiveRestaurantList:  any[] = [];

  selectedVenueType: number | null = null;
  // selectedRegisterdRestaurantId: string;
  selectedBarId: number = 0; // Default to "Please Select"
  selectedAddress: string = '';
  showRestaurantformData :boolean = false;

  showMapformData :boolean = false;


  private geoCoder;
  //Injecting SErvices
  @ViewChild("search", { static: false })
  
  public searchElementRef: ElementRef;
  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public myapp: AppComponent
  ) {}

  
  ngOnInit() {
    //maxDate = new Date(2017, 5, 21);
    this.barUserType =localStorage.getItem("userType");
    //Special Event Form Declaration with form validation for the fields
    this.loadRestaurant();
    this.mapsAPILoader.load().then(() => {
      console.log('ppp');
      
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();
      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        {
          types: [],
          // componentRestrictions: { country: "IN" }
        }
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          this.isMapShow = true;
          //console.log("isMapShow stattus is true");
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          // console.log(place);
          

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.customAddress = place.formatted_address;
          // //alert('lat:'+this.latitude +' long:'+this.longitude);
          this.specialeventForm.controls["lat"].patchValue(this.latitude);
          this.specialeventForm.controls["long"].patchValue(this.longitude);
          this.specialeventForm.controls["address"].patchValue(this.customAddress);

          //this.specialeventForm.controls["addressMap"].patchValue(this.customAddress);
          // this.zoom = 10;
        });
      });
    });
    if (this.barUserType == 'EM') {
      
      this.specialeventForm = this.fb.group({
        title: [
          "",
          [
            Validators.required,
            //Validators.pattern("^[a-zA-Z ()]+$"),
            Validators.minLength(3),
            Validators.maxLength(200)
          ]
        ],
        barId: [localStorage.getItem("barId")],
        id: [""],
        lat: [""],
        long: [""],
        address: [""],
        selectedRegisterdRestaurantId: [""],
        selectedVenueType:[0],
        publishedOn: ["", [Validators.required]],
        duration: ["", [Validators.required]],
        description: ["", [Validators.minLength(20), Validators.maxLength(1000)]],
        venue: [
          "",
          [
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(500)
          ]
        ],
        startDateTime: ["", [Validators.required]],
        endDateTime: [""], // [Validators.required]
        entryfees: ["", [Validators.pattern("^[0-9() . ]+$")]],
        malestagentryfees: ["", [Validators.pattern("^[0-9() . ]+$")]],
        femalestagEntryFees: ["", [Validators.pattern("^[0-9() . ]+$")]],
        coverChargesformalestage: ["", [Validators.pattern("^[0-9() . ]+$")]],
        coverChargesForfemalestag: ["", [Validators.pattern("^[0-9() . ]+$")]],
  
        coupleEntryFees: ["", [Validators.pattern("^[0-9() . ]+$")]],
        coverCharges: [
          "",
          [Validators.pattern("^[0-9() . ]+$"), Validators.maxLength(5)]
        ],
        coverChargesForCouples: [
          "",
          [Validators.pattern("^[0-9() . ]+$"), Validators.maxLength(5)]
        ],
        totalTickets: [
          "",
          [Validators.required, Validators.pattern("^[[0-9()]+$")]
        ],
        //totalCoupleTickets: ["", [Validators.pattern("^[[0-9()]+$")]],
        discountRate: ["", [Validators.pattern("^[0-9() . ]+$")]],
        eventImage: [""],
        startTime: [""],
        endTime: [""],
        person2: [""],
        person4: [""],
        person6: [""],
        person8: [""],
        person10: [""],
        person12: [""],
        
        person2_table_number: [""],
        person4_table_number: [""],
        person6_table_number: [""],
        person8_table_number: [""],
        person10_table_number: [""],
        person12_table_number: [""],
  
        person2_price: [""],
        person4_price: [""],
        person6_price: [""],
        person8_price: [""],
        person10_price: [""],
        person12_price: [""],
        dressCode: [""],
        addressMap:[""],
        selectedAddress: [''],
        // selectedRegisterdRestaurantId:[''],
        agree: ["", [Validators.required]]
      });
    }else{
      this.specialeventForm = this.fb.group({
        title: [
          "",
          [
            Validators.required,
            //Validators.pattern("^[a-zA-Z ()]+$"),
            Validators.minLength(3),
            Validators.maxLength(200)
          ]
        ],
        barId: [localStorage.getItem("barId")],
        id: [""],
        publishedOn: ["", [Validators.required]],
        duration: ["", [Validators.required]],
        description: ["", [Validators.minLength(20), Validators.maxLength(1000)]],
        venue: [
          "",
          [
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(500)
          ]
        ],
        startDateTime: ["", [Validators.required]],
        endDateTime: [""], // [Validators.required]
        entryfees: ["", [Validators.pattern("^[0-9() . ]+$")]],
        malestagentryfees: ["", [Validators.pattern("^[0-9() . ]+$")]],
        femalestagEntryFees: ["", [Validators.pattern("^[0-9() . ]+$")]],
        coverChargesformalestage: ["", [Validators.pattern("^[0-9() . ]+$")]],
        coverChargesForfemalestag: ["", [Validators.pattern("^[0-9() . ]+$")]],
  
        coupleEntryFees: ["", [Validators.pattern("^[0-9() . ]+$")]],
        coverCharges: [
          "",
          [Validators.pattern("^[0-9() . ]+$"), Validators.maxLength(5)]
        ],
        coverChargesForCouples: [
          "",
          [Validators.pattern("^[0-9() . ]+$"), Validators.maxLength(5)]
        ],
        totalTickets: [
          "",
          [Validators.required, Validators.pattern("^[[0-9()]+$")]
        ],
        //totalCoupleTickets: ["", [Validators.pattern("^[[0-9()]+$")]],
        discountRate: ["", [Validators.pattern("^[0-9() . ]+$")]],
        eventImage: [""],
        startTime: [""],
        endTime: [""],
        person2: [""],
        person4: [""],
        person6: [""],
        person8: [""],
        person10: [""],
        person12: [""],
        
        person2_table_number: [""],
        person4_table_number: [""],
        person6_table_number: [""],
        person8_table_number: [""],
        person10_table_number: [""],
        person12_table_number: [""],
  
        person2_price: [""],
        person4_price: [""],
        person6_price: [""],
        person8_price: [""],
        person10_price: [""],
        person12_price: [""],
        dressCode: [""],
  
  
        agree: ["", [Validators.required]]
      });


      
    }
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();
    this.todayDay = yyyy + "-" + mm + "-" + dd;

    //loadspecialEventFeeSetup function defile
    const feesParam = new FormData();
    feesParam.append("barId", localStorage.getItem("barId"));
    feesParam.append("token", localStorage.getItem("token"));
    this.bs.callAPI(feesParam, "getSpecialEventsFees").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.eventSetupFeeList = response.data;
          this.isEventPriceEnable = response.isEventPriceEnable;
          this.venue.setValue(response.address);
        } else {
          this.isEventPriceEnable = response.isEventPriceEnable;
          this.venue.setValue(response.address);
        }

        //Just to bypass the validation
        if (!this.isEventPriceEnable) {
          this.duration.setValue(0);
        }
      },
      error => {
        console.log(error);
      }
    );

    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get("id"));
      if (id) {
        this.loadEventData(id);
        this.showCancelBtn = true;
        this.actionType = "edit";
        this.pageTitle = "Edit Special Event";
        this.editDuration = true;
        //this.calculateValue();
      } else {
        this.actionType = "add";
        this.pageTitle = "Add Special Event";
        this.editDuration = false;
        this.editPublishOn = true;
      }
    });
  }


  onVenueTypeChange(selectedVenueType: number) {
    // alert('ppp');
    // this.selectedVenueType = Number(this.selectedVenueType);
    // const selectedVenueType = this.specialeventForm.get('selectedVenueType').value;
    
    // Reset related fields based on the venue type selection
    if (selectedVenueType == 1) {
      // Show registered restaurants options
      this.showRestaurantformData =true;
      this.showMapformData =false;
      // this.isMapShow = false;
      

    } else if (selectedVenueType == 2) {
      // Show map address section
      this.showMapformData =true;
      this.showRestaurantformData =false;
      // this.isMapShow = true;
      this.specialeventForm.get('selectedAddress').setValue(''); // Clear the address if switching to map
    } else {
      // Hide both sections if no valid option selected
      // this.isMapShow = true;
    }
  }

  onRestaurantSelect(barId: any) {
      
    this.selectedBarId =Number(barId);
    this.specialeventForm.get('selectedRegisterdRestaurantId').setValue(barId);
    
  const selectedRestaurant = this.ActiveRestaurantList.find(
    (restaurant) => restaurant.barId  == this.selectedBarId // Compare with barId in the restaurant object
  );
  
    this.selectedAddress = selectedRestaurant ? selectedRestaurant['address'] : '';
    const address = selectedRestaurant ? selectedRestaurant['address'] : '';
    this.specialeventForm.get('selectedAddress').setValue(address);
    // console.log(this.selectedAddress);
    // this.selectedAddress.push(this.fb.control(address));
    // this.specialeventForm.get('selectedAddress').setValue(this.selectedAddress);

    // /alert(this.selectedAddress);

  }
  imageCropped(event: ImageCroppedEvent) {
    console.log("Cropping: " + JSON.stringify(event));
    this.eventImagePath = event.base64;
    this.specialeventForm.get("eventImage").setValue(event.base64);
    this.base64Img = "ok";
  }

  hideCropImg() {
    this.imageSave = false;
  }

  /*skipCropImg() {
    this.imageSave = false;
    this.specialeventForm.get("eventImage").setValue(this.eventImageSave);
  }*/

  //on File select, put the selected file in Profile Form model variable
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      var fileMimes = file.type.split("/");
      var fileType = fileMimes[0];
      console.log(fileType);

      if (fileType === "image") {
        this.imageSave = true;
        this.FileErrorMessageLog = false;
        this.eventImageSave = this.specialeventForm
          .get("eventImage")
          .setValue(file);
        this.specialeventForm.get("eventImage").setValue(file);
        this.imageChangedEvent = event;
        //Preveiw of the image only on images
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = _event => {
          this.eventImagePath = reader.result;
        };
        return;
      } else {
        this.FileErrorMessageLog = true;
      }
    }
  }
  loadRestaurant(){
    //alert('ppp');
    this.myapp.hideAlerts();
    const barData = new FormData();
    // barData.append("id", );
    barData.append("token", localStorage.getItem("token"));

    this.bs.callAPI(barData, "restaurantLists").subscribe(
      (response: any) => {
        // this.ActiveRestaurantList =response.data;
      // console.log( response.data);
      // console.log('ddffeess');
      
        
        
        if (response.status == 1) {
          this.ActiveRestaurantList =response.data;
          // console.log(this.ActiveRestaurantList);
          // this.ActiveRestaurantList = response.data.map((restaurant) => ({
          //   barId: restaurant.barId,
          //   address: restaurant.address,
          //   // Map other properties if needed
          // }));
          
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  //Function to calculate Ad expiry date
  calculateValue() {
    this.viewDetail = true;
    this.expire = this.specialeventForm.get("publishedOn").value;
    let expireValue = 0;
    //alert(this.expire);
    if (
      this.expire == undefined ||
      this.expire == null ||
      !this.expire ||
      !this.duration.value
    ) {
      this.finalValue = 0;
      this.eventFee = 0;
    } else {
      this.published = new Date(this.specialeventForm.get("publishedOn").value);
      this.expire = new Date(this.specialeventForm.get("publishedOn").value);
      let newdate = new Date();
      expireValue = newdate.setDate(
        this.expire.getDate() + this.duration.value * 7
      );
      let customDate = new Date(expireValue);
      let dd: any = customDate.getDate();
      let mm: any = customDate.getMonth() + 1;
      if (dd <= 9) {
        dd = String(dd).padStart(2, "0");
      }
      if (mm <= 9) {
        mm = String(mm).padStart(2, "0");
      }
      let formatedDate = dd + "-" + mm + "-" + customDate.getFullYear();
      //alert(formatedDate

      this.finalValue = formatedDate;
      for (let item of this.eventSetupFeeList) {
        if (item.duration == this.duration.value) {
          this.eventFee = item.price;
          //alert(this.eventFee);
        }
      }
    }
  }

  //Function to submit data
  onEventSubmit() {
    this.myapp.hideAlerts();
    let canSubmit = true;
    //Date conversion for db
    let formatedStartDate = this.cs.stringToDateFun(this.startDateTime.value);
    let formatedEndDate = ""; // this.cs.stringToDateFun(this.endDateTime.value);
    let formatedPublishdDate = this.cs.stringToDateFun(this.publishedOn.value);
    //Function to validate End date is grater then start time
    /*if (formatedStartDate > formatedEndDate) {
      this.DateErrorMessage = true;
      canSubmit = false;
      return;
    } else if (formatedStartDate == formatedEndDate) {
    } else {
      this.DateErrorMessage = false;
      canSubmit = true;
    } */

    //Function to validate Publish Date is grater then Event Date
    //console.log("publishedValue" + formatedPublishdDate);
    //console.log("startDateTimeValue" + formatedStartDate);
    if (formatedPublishdDate > formatedStartDate) {
      this.PublishErrorMessage = true;
      //console.log("can not submit");
      canSubmit = false;
      return;
    } else {
      //console.log("can submit");
      this.PublishErrorMessage = false;
    }

    //Function to validate End time is grater then start time
    /*let startValue = this.startTime.value;
    let endValue = this.endTime.value;

    let starttime = this.cs.timevalidation(startValue);
    let endtime = this.cs.timevalidation(endValue);*/
    //console.log("startValue" + startValue);
    /*if (startValue.length && endValue.length) {
      if (starttime >= endtime) {
        this.TimeErrorMessage = true;
        canSubmit = false;
        return;
      } else {
        this.TimeErrorMessage = false;
        canSubmit = true;
      }
    }*/

    // VAlidating minimum 1 hr interval
    /*if (startValue.length && endValue.length) {
      let starttotal = moment(startValue, "hh:mm:ss")
        .add(59, "minutes")
        .format("LTS");
      let endtotal = moment(endValue, "hh:mm:ss")
        .subtract(0, "minutes")
        .format("LTS");
      if (starttotal > endtotal || starttotal == endtotal) {
        this.MinuteErrorMessage = true;
        canSubmit = false;
        return;
      } else {
        this.MinuteErrorMessage = false;
        canSubmit = true;
      }
    }*/

    // Function to validate discount
    let discountValue = this.discountRate.value;
    if (discountValue.length > 0) {
      if (discountValue == 0) {
        // this.DiscountErrorMessage = true;
        // this.DiscountMaxErrorMessage = false;
        // canSubmit = false;
        // return;
        this.DiscountErrorMessage = false;
        this.DiscountMaxErrorMessage = false;
      } else if (discountValue > 100) {
        this.DiscountErrorMessage = false;
        this.DiscountMaxErrorMessage = true;
        canSubmit = false;
        return;
      } else {
        this.DiscountErrorMessage = false;
        this.DiscountMaxErrorMessage = false;
      }
    }

    // Function to validate discount
    // let discountRateValue = this.discountRate.value;
    // if (discountRateValue > 100) {
    //   this.DiscountMaxErrorMessage = true;
    //   canSubmit = false;
    //   return;
    // } else {
    //   this.DiscountErrorMessage = false;
    //   this.DiscountMaxErrorMessage = false;
    //   canSubmit = true;
    // }

    //Validation agree is checked or not
    if (!this.agree.value) {
      canSubmit = false;
      let msg = "Please agree before publishing this event";
      this.myapp.showErrorAlert = true;
      this.myapp.errorMsg = msg;
    }

    if (canSubmit) {
      let dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          message:
            "Changes and Deletion of offer won't be allowed after publishing. Do you want to publish the event?"
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        //Result is coming from the buttons on the popup
        if (result == true) {
          this.myapp.isLoadingResults = true;
          const formData = new FormData();
          formData.append("barId", localStorage.getItem("barId"));
          formData.append("id", this.specialeventForm.get("id").value);
          formData.append("title", this.specialeventForm.get("title").value);
          formData.append("dressCode", this.specialeventForm.get("dressCode").value);

          formData.append("startDate", formatedStartDate);
          formData.append("endDate", formatedEndDate);
          formData.append(
            "description",
            this.specialeventForm.get("description").value
          );
          formData.append("venue", this.specialeventForm.get("venue").value);
          formData.append(
            "entryfees",
            this.specialeventForm.get("entryfees").value
          );
          formData.append(
            "coupleEntryFees",
            this.specialeventForm.get("coupleEntryFees").value
          );
          formData.append(
            "malestagentryfees",
            this.specialeventForm.get("malestagentryfees").value
          );
          formData.append(
            "femalestagEntryFees",
            this.specialeventForm.get("femalestagEntryFees").value
          );
          formData.append(
            "coverChargesformalestage",
            this.specialeventForm.get("coverChargesformalestage").value
          );
          formData.append(
            "coverChargesForfemalestag",
            this.specialeventForm.get("coverChargesForfemalestag").value
          );

          formData.append(
            "totalTickets",
            this.specialeventForm.get("totalTickets").value
          );
          /*formData.append(
            "totalCoupleTickets",
            this.specialeventForm.get("totalCoupleTickets").value
          );*/
          formData.append(
            "coverCharges",
            this.specialeventForm.get("coverCharges").value
          );
          formData.append(
            "coverChargesForCouples",
            this.specialeventForm.get("coverChargesForCouples").value
          );
          formData.append(
            "discountRate",
            this.specialeventForm.get("discountRate").value
          );
          formData.append("publishedOn", formatedPublishdDate);
          formData.append(
            "duration",
            this.specialeventForm.get("duration").value
          );
          formData.append(
            "startTime",
            this.specialeventForm.get("startTime").value
          );
          formData.append(
            "endTime",
            this.specialeventForm.get("endTime").value
          );
          formData.append(
            "eventImage",
            this.specialeventForm.get("eventImage").value
          );
          if (this.base64Img == "ok")
            formData.append("base64Img", this.base64Img);
            formData.append("token", localStorage.getItem("token"));

            formData.append("person2", this.specialeventForm.get("person2").value);
            formData.append("person2_table_number", this.specialeventForm.get("person2_table_number").value);
            formData.append("person2_price", this.specialeventForm.get("person2_price").value);

            formData.append("person4", this.specialeventForm.get("person4").value);
            formData.append("person4_table_number", this.specialeventForm.get("person4_table_number").value);
            formData.append("person4_price", this.specialeventForm.get("person4_price").value);

            formData.append("person6", this.specialeventForm.get("person6").value);
            formData.append("person6_table_number", this.specialeventForm.get("person6_table_number").value);
            formData.append("person6_price", this.specialeventForm.get("person6_price").value);

            formData.append("person8", this.specialeventForm.get("person8").value);
            formData.append("person8_table_number", this.specialeventForm.get("person8_table_number").value);
            formData.append("person8_price", this.specialeventForm.get("person8_price").value);

            formData.append("person10", this.specialeventForm.get("person10").value);
            formData.append("person10_table_number", this.specialeventForm.get("person10_table_number").value);
            formData.append("person10_price", this.specialeventForm.get("person10_price").value);

            formData.append("person12", this.specialeventForm.get("person12").value);
            formData.append("person12_table_number", this.specialeventForm.get("person12_table_number").value);
            formData.append("person12_price", this.specialeventForm.get("person12_price").value);
            formData.append("createdBy", localStorage.getItem("userType"));

            //isFreeValue
            formData.append("isFreeValue", this.isFreeValue);

            if (localStorage.getItem("userType") == 'EM') {
              formData.append("selectedVenueType", this.specialeventForm.get("selectedVenueType").value);
              formData.append("selectedRegisterdRestaurantId", this.specialeventForm.get("selectedRegisterdRestaurantId").value);
              formData.append("selectedAddress", this.selectedAddress);
              formData.append("addressMap", this.specialeventForm.get('address').value);
              formData.append("latitude", this.specialeventForm.get('lat').value);
              formData.append("longitude", this.specialeventForm.get('long').value);
            }
 
          //Form submit by appi call
          this.bs
            .callAPI(formData, "addEditSpecialEvents")
            .subscribe(response => {
              console.log(response);
              
              if (response.status === 1) {
                this.myapp.showSuccessAlert = true;
                this.myapp.successMsg = response.message;
                this.myapp.isLoadingResults = false;
                if (this.actionType == "edit") {
                  //this.router.navigate(["/add-special-event/" + response.data.id]);
                  this.router.navigate(["/view-special-event"]);
                } else {
                  //alert(this.actionType);
                  this.router.navigate(["/view-special-event"]);
                }
              } else {
                this.myapp.showErrorAlert = true;
                this.myapp.errorMsg = response.message;
                this.myapp.isLoadingResults = false;
              }
            });
        }
      });
    }
  }
  //load event data by id
  loadEventData(id) {
    const eventData = new FormData();
    eventData.append("id", id);
    eventData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(eventData, "getSpecialEventsData").subscribe(
      (response: any) => {
        console.log(response);
        
        if (response.status == 1) {
          //Hide the publish button
          if (response.data.status != 2) this.showPublishBtn = false;
          if (response.data.is_free == 1) {
            this.isFree = true;
            this.isFreeValue =1;
          } else {
            this.isFree = false;
            this.isFreeValue =0;
          }

          if (response.data.coverCharges == 0) response.data.coverCharges = "";
          if (response.data.coverChargesForCouples == 0)
            response.data.coverChargesForCouples = "";
          if (response.data.discountRate == 0) response.data.discountRate = 0;
          if (response.data.malestagentryfees == 0) response.data.malestagentryfees = "";
          if (response.data.femalestagEntryFees == 0) response.data.femalestagEntryFees = "";
          if (response.data.coverChargesformalestage == 0) response.data.coverChargesformalestage = "";
          if (response.data.coverChargesForfemalestag == 0) response.data.coverChargesForfemalestag = "";


          this.specialeventForm.patchValue({
            barId: localStorage.getItem("barId"),
            id: response.data.id,
            title: response.data.title,
            dressCode: response.data.dressCode,
            startDateTime: response.data.startDate,
            endDateTime: response.data.endDate,
            description: response.data.description,
            venue: response.data.venue,
            entryfees: response.data.entryfees,
            coupleEntryFees: response.data.coupleEntryFees,
            totalTickets: response.data.totalTickets,
            malestagentryfees: response.data.malestagentryfees,
            femalestagEntryFees: response.data.femalestagEntryFees,
            coverChargesformalestage: response.data.coverChargesformalestage,
            coverChargesForfemalestag: response.data.coverChargesForfemalestag,

            //totalCoupleTickets: response.data.totalCoupleTickets,
            coverCharges: response.data.coverCharges,
            coverChargesForCouples: response.data.coverChargesForCouples,
            discountRate: response.data.discountRate,
            publishedOn: response.data.publishedOn,
            startTime: this.cs.timeWithoutSeconds(response.data.startTime), //response.data.startTime,
            endTime: this.cs.timeWithoutSeconds(response.data.endTime), //response.data.endTime,
            duration: response.data.duration,

            person2: (response.data.person2_table == 1) ? true :false ,
            person4: (response.data.person4_table == 1) ? true :false,
            person6: (response.data.person6_table == 1)? true :false,
            person8: (response.data.person8_table == 1) ? true :false,
            person10: (response.data.person10_table == 1) ? true :false,
            person12: (response.data.person12_table == 1) ? true :false,

            person2_table_number: response.data.person2_table_number,
            person4_table_number: response.data.person4_table_number,
            person6_table_number: response.data.person6_table_number,
            person8_table_number: response.data.person8_table_number,
            person10_table_number: response.data.person10_table_number,
            person12_table_number: response.data.person12_table_number,

            person2_price: response.data.person2_price,
            person4_price: response.data.person4_price,
            person6_price: response.data.person6_price,
            person8_price: response.data.person8_price,
            person10_price: response.data.person10_price,
            person12_price: response.data.person12_price,

          });
          if (
            response.data.eventImage == "" ||
            response.data.eventImage == undefined ||
            response.data.eventImage == null
          ) {
          } else {
            this.eventImagePath =
              this.cs.eventPath() + response.data.eventImage;
          }
          if (response.data.publishedOn <= this.todayDay) {
            //alert('publish date:'+response.data.publishedOn +" today Date:"+this.todayDay);
            this.editPublishOn = false;
          } else {
            this.editPublishOn = true;
          }
          this.calculateValue();
        } else {
          this.myapp.showErrorAlert = true;
          this.myapp.errorMsg = response.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }


  markerDragEnd($event: MouseEvent) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }


  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 10;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: parseFloat(latitude), lng: parseFloat(longitude) } },
      (results, status) => {
        console.log(results);
        console.log(status);
        if (status === "OK") {
          if (results[0]) {
            // alert('ppp');
            this.zoom = 10;
            this.customAddress = results[0].formatted_address;
            this.specialeventForm.controls["lat"].patchValue(latitude);
            this.specialeventForm.controls["long"].patchValue(longitude);
            this.specialeventForm.controls["address"].patchValue(this.customAddress);
            // this.specialeventForm.controls["addressMap"].patchValue(this.customAddress);
          } else {
            // window.alert('No results found');
          }
        } else {
          //window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }
  //Reset the Form via Cancel Button
  resetForm() {
    this.specialeventForm.reset();
    //Patch the required data in Form Modal
    this.specialeventForm.patchValue({
      id: "",
      barId: [localStorage.getItem("barId")],
      title: "",
      publishedOn: "",
      duration: "",
      description: "",
      venue: "",
      startDateTime: "",
      endDateTime: "",
      entryfees: "",
      coupleEntryFees: "",
      malestagentryfees: "",
      femalestagEntryFees: "",
      coverChargesformalestage: "",
      coverChargesForfemalestag: "",
      coverCharges: "",
      coverChargesForCouples: "",
      totalTickets: "",
      //totalCoupleTickets: "",
      discountRate: "",
      eventImage: "",
      startTime: "",
      endTime: "",
      person2:"",
      person2_table_number:"",
      person2_price:"",

      person4:"",
      person4_table_number:"",
      person4_price:"",

      person6:"",
      person6_table_number:"",
      person6_price:"",

      person8:"",
      person8_table_number:"",
      person8_price:"",

      person10:"",
      person10_table_number:"",
      person10_price:"",

      person12:"",
      person12_table_number:"",
      person12_price:"",
      dressCode:"",
    });
    this.viewDetail = false;
    this.eventFee = 0;
    this.finalValue = 0;
    this.published = new Date();
    this.eventImagePath = null;
  }

  changeSpicalEvent() {
    console.log('done');
    if (this.isFree ==true) {
      this.isFreeValue =1;
      
      this.isFree = false;
      
    } else {
      this.isFreeValue =0;
      
      this.isFree=true;

    }
    

  }


  get selectedRegisterdRestaurantId() {
    return this.specialeventForm.get("selectedRegisterdRestaurantId");
  }
  // get selectedVenueType() {
  //   return this.specialeventForm.get("selectedVenueType");
  // }
  get address() {
    return this.specialeventForm.get("address");
  }
  get addressMap() {
    return this.specialeventForm.get("addressMap");
  }
  get lat() {
    return this.specialeventForm.get("lat");
  }
  get long() {
    return this.specialeventForm.get("long");
  }
  //Getor - To access the fields directly in the form in HTML
  get person2() {
    return this.specialeventForm.get("person12");
  }
  
  get person4() {
    return this.specialeventForm.get("person4");
  }
  get person6() {
    return this.specialeventForm.get("person6");
  }
  get person8() {
    return this.specialeventForm.get("person8");
  }
  get person10() {
    return this.specialeventForm.get("person10");
  }
  get person12() {
    return this.specialeventForm.get("person12");
  }
  get person2_table_number() {
    return this.specialeventForm.get("person2_table_number");
  }
  get person4_table_number() {
    return this.specialeventForm.get("person4_table_number");
  }
  get person6_table_number() {
    return this.specialeventForm.get("person6_table_number");
  }
  get person8_table_number() {
    return this.specialeventForm.get("person8_table_number");
  }
  get person10_table_number() {
    return this.specialeventForm.get("person10_table_number");
  }
  get person12_table_number() {
    return this.specialeventForm.get("person12_table_number");
  }
  get person2_price() {
    return this.specialeventForm.get("person2_price");
  }
  get person4_price() {
    return this.specialeventForm.get("person4_price");
  }
  get person6_price() {
    return this.specialeventForm.get("person6_price");
  }
  get person8_price() {
    return this.specialeventForm.get("person8_price");
  }
  get person10_price() {
    return this.specialeventForm.get("person10_price");
  }
  get person12_price() {
    return this.specialeventForm.get("person12_price");
  }
  get dressCode() {
    return this.specialeventForm.get("dressCode");
  }

  get title() {
    return this.specialeventForm.get("title");
  }
  get publishedOn() {
    return this.specialeventForm.get("publishedOn");
  }
  get duration() {
    return this.specialeventForm.get("duration");
  }
  get description() {
    return this.specialeventForm.get("description");
  }
  get venue() {
    return this.specialeventForm.get("venue");
  }
  get startDateTime() {
    return this.specialeventForm.get("startDateTime");
  }
  get endDateTime() {
    return this.specialeventForm.get("endDateTime");
  }
  get entryfees() {
    return this.specialeventForm.get("entryfees");
  }
  get coupleEntryFees() {
    return this.specialeventForm.get("coupleEntryFees");
  }
  get coverCharges() {
    return this.specialeventForm.get("coverCharges");
  }
  get coverChargesForCouples() {
    return this.specialeventForm.get("coverChargesForCouples");
  }
  get totalTickets() {
    return this.specialeventForm.get("totalTickets");
  }
  /*get totalCoupleTickets() {
    return this.specialeventForm.get("totalCoupleTickets");
  }*/
  get discountRate() {
    return this.specialeventForm.get("discountRate");
  }
  get eventImage() {
    return this.specialeventForm.get("eventImage");
  }

  get endTime() {
    return this.specialeventForm.get("endTime");
  }
  get startTime() {
    return this.specialeventForm.get("startTime");
  }
  get agree() {
    return this.specialeventForm.get("agree");
  }
}
