import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"]
})
export class SidebarComponent implements OnInit {
  //Variable declration
  ActiveClass = null;
  barName = "";
  rank = "0";
  logo = null;
  logoPath = this.cs.barLogoPath();
  adminMenu = false;
  adminStaffAccountMenu = false;
  barMenu = false;
  barMenuMaster = false;
  // isMasterbarMenu = false;
  barStaffAccount =false;
  staffMenu = false;
  roleId: any = 0;
  stafType="";

  constructor(
    private router: Router,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {
    
    if (window.localStorage.getItem("userType") == "A") {
      this.adminMenu = true;
    } else if (window.localStorage.getItem("userType") == "S") {
      this.staffMenu = true;
    } else {
     
        this.barMenu = true;
      
      this.loadBarRank(); //Call the Bar Rank
    }
    if (window.localStorage.getItem("isMaster") == '1') {
    this.barMenuMaster = true;
    }
     this.stafType =window.localStorage.getItem("stafType");

    this.roleId = window.localStorage.getItem("roleId");
  }

  //Load Bar Rank Defination
  loadBarRank() {
    this.myapp.hideAlerts();
    const barData = new FormData();
    barData.append("barId", localStorage.getItem("barId"));
    barData.append("token", localStorage.getItem("token"));
    this.bs.callAPI(barData, "getBarRank").subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.barName = this.myapp.loginBarName = response.data.barName;
          this.myapp.loginBarRank = this.rank = response.data.rank;

          this.logo = response.data.logo;
          if (response.data.logo)
            this.myapp.loginBarLogo = this.logoPath + response.data.logo;

          if (response.data.panelCoverImg)
            this.myapp.loginBarCoverImg =
              this.cs.barBannerPath() + response.data.panelCoverImg;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  //Logout Function
  /*logout() {
    const postData = new FormData();
    postData.append("token", window.localStorage.getItem("token"));
    if (window.localStorage.getItem("userType") == "B") {
      postData.append("barId", window.localStorage.getItem("barId"));
    } else {
      postData.append("userId", window.localStorage.getItem("userId"));
    }

    this.bs.callAPI(postData, "barLogout").subscribe(response => {
      if (response.status === 1) {
        console.log(response);
        //Destory the local storage
        localStorage.clear();
        window.location.href = "./login";
      } else {
        this.myapp.showErrorAlert = true;
        this.myapp.errorMsg = response.message;
        this.myapp.isLoadingResults = false;
      }
    });
    return false;
  }*/
}
