import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { BarService } from "../../service/bar.service";
import { CommonService } from "../../service/common.service";
import { AppComponent } from "../../app.component";
import { ImageCroppedEvent } from "ngx-image-cropper";
import * as moment from "moment";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";

@Component({
  selector: 'app-add-multipal-restaurant',
  templateUrl: './add-multipal-restaurant.component.html',
  styleUrls: ['./add-multipal-restaurant.component.css']
})
export class AddMultipalRestaurantComponent implements OnInit {
  pageHeading = "Add Multipal Restaurants";
  // barNam = "";
  happyhourForm: FormGroup;
  isMultipleRestaurants: boolean = false;

  forgotForm: FormGroup;
  invalidLogin: boolean = false;
  validForgot: boolean = false;
  invalidForgot: boolean = false;
  showForgotForm: boolean = false;
  showbarForm: boolean = true;
  isLoadingResults = false;
  forgotMessage = "";
  loginMessage = "";
  restaurantDetailsActive =false;
   restaurantDetailsId ="";
   roleType ="";
  
  barForm:FormGroup;
  error = false;
  success = false;
  response: string = "";
  restaurantDetailsType ="";
  restaurantDetailsPassword ="";
  restaurantDetailsName ="";
  cities: any[];
  allCities = [];
  dropdownSettings = {};
  selectedItems = [];
  selectedIds: number[] = [];
  cityNew: number = 0;
  cityControl: any[];
  allCountries:[];
  allStates = [];
  allAreas=[];
  
 
  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bs: BarService,
    private cs: CommonService,
    public myapp: AppComponent
  ) {}

  ngOnInit() {


    // if (localStorage.getItem("isMaster") == '1') {
    //   this.isMultipleRestaurants = true;
    // }
    this.barForm = this.fb.group({
      barName: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(200)
        ]
      ],
      localArea: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(200)
        ]
      ],
      countryId: [],
      stateId: [],
      cityId: [],
      terms: ["", [Validators.required]],
      // roleId:["",[Validators.required]]
    });
    this.loadCountries();
    // this.loadCities(null);
    this.loadStates();
    this.loadAreas(null);
    this.dropdownSettings= {
      singleSelection: true,
      idField: 'city_id',
      textField: 'city_name',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
    //Happy Hour Form Model Declaration with form validation for the fields
    
  }

  //Login Form Submit funtion
  onSubmit() {

    // alert('pppp');
    this.error = false;
    this.success = false;
    //Validation checking
    if (this.barForm.invalid) {
      return;
    }
    else if (this.terms.value == false) {
      this.error = true;
      this.response = "Please accept the service agreement";
      return;
    }

    const formData = new FormData();
    formData.append("barName", this.barForm.get("barName").value);
    // formData.append("roleId", this.barForm.get("roleId").value);
    formData.append("localArea", this.barForm.get("localArea").value);
    formData.append("cityIdOld", this.cityNew.toString());
    // formData.append("countryId", this.barForm.get("countryId").value);
    formData.append("stateId", this.barForm.get("stateId").value);
    formData.append("cityId", this.barForm.get("cityId").value);
    formData.append("masterBarId", localStorage.getItem("barId"));
    
    
    this.bs.callAPI(formData, "addSubBarMaster").subscribe(
      (response: any) => {
        console.log(response);
      if (response.status === 1) {
        this.success = true;

        this.response = response.message;
        this.restaurantDetailsName =response.data.barName;
        this.restaurantDetailsPassword =response.data.password;
        this.restaurantDetailsId =response.data.userName;
        this.restaurantDetailsType =response.data.userType;        
        this.restaurantDetailsActive =true;
        window.localStorage.setItem("subBarIds", response.data.subBarIds);
        this.myapp.isLoadingResults = false;
          this.myapp.showSuccessAlert = true;
          this.myapp.successMsg = response.message;
         this.router.navigate(["/my-multipal-restaurant"]);
        // subBarIds
        // this.barForm.reset();

      } else {
        this.error = true;
        this.response = response.message;
      }
    });
    
  }
  //Getor - To access the userName directly in the form in HTML
  get barName() {
    return this.barForm.get("barName");
  }
  get roleId() {
    return this.barForm.get("roleId");
  }
  get localArea() {
    return this.barForm.get("localArea");
  }
  get countryId() {
    return this.barForm.get("countryId");
  }
  get stateId() {
    return this.barForm.get("stateId");
  }
  get cityId() {
    return this.barForm.get("cityId");
  }
  
  get terms() {
    return this.barForm.get("terms");
  }


  copyData() {
    // Create a temporary textarea element
    const textarea = document.createElement('textarea');
    if (this.restaurantDetailsType == "EM") {
      textarea.value = 'Event Manager ID - '+this.restaurantDetailsId+'\n Password - '+this.restaurantDetailsPassword;

    }else if(this.restaurantDetailsType == "B"){
      textarea.value = 'Restaurant ID - '+this.restaurantDetailsId+'\n Restaurant Password - '+this.restaurantDetailsPassword;

    }
    // Set the value of the textarea to the text you want to copy
    // textarea.value = 'Restaurant ID - '+this.restaurantDetailsId+'\nRestaurant Password - '+this.restaurantDetailsPassword;

    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }
  //Load States
  loadStates() {
    this.myapp.hideAlerts();
    const countryId = this.barForm.get("countryId").value;
    // const countryId = 
    // if (countryId != "0") {
      const formData = new FormData();
      formData.append("barId", localStorage.getItem("barId"));
      formData.append("token", localStorage.getItem("token"));
      this.bs.callAPI(formData, "getAllMasterStates").subscribe(
        (response: any) => {
          //console.log(response);
          if (response.status == 1) {
            this.allStates = response.data;
            // alert('opp');
            console.log(this.allStates);
            
            // this.allTimeZone = response.time_zone;
            // this.allcurrency = response.currencies
          }
        },
        error => {
          console.log(error);
        }
      );
    // } else this.allStates = [];
  }
  loadCountries() {
    this.myapp.hideAlerts();
    
      const formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      this.bs.callAPI(formData, "getAllCountries").subscribe(
        //this.myapp.isLoadingResults = false;
        (response: any) => {
          // console.log(response);
          if (response.status == 1) {           
            this.allCountries = response.data;

          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        },
        error => {
          console.log(error);
        }
      );
  }


  //Load areas, on City change event
  loadAreas(event) {
    this.myapp.hideAlerts();
    const cityId = this.barForm.get("cityId").value;
    if (event != null) {
      this.barForm.patchValue({
        address: ""
      });
    }
    if (cityId != "0") {
      const formData = new FormData();
      formData.append("cityId", cityId);
      formData.append("token", localStorage.getItem("token"));
      this.bs.callAPI(formData, "getAllAreas").subscribe(
        (response: any) => {
          //console.log(response);
          if (response.status == 1) {
            this.allAreas = response.data;
          } else {
            this.allAreas = [];
            /*this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;*/
          }
        },
        error => {
          console.log(error);
        }
      );
    } else this.allCities = [];
  }

  onItemSelect(event: any) {
    
    console.log(event.item_id);
    this.cityNew =event.item_id;
    console.log('lkkkk');
    console.log(this.cityNew);  
  }
  
  logSelectedItems(id: number) {
    this.selectedIds.push(id);
    console.log(this.selectedIds.join(','));
  }

  loadCities(event) {
    this.myapp.hideAlerts();
    const stateId = this.barForm.get("stateId").value;

    if (stateId != "0") {
      //this.myapp.isLoadingResults = true;
      const formData = new FormData();
      formData.append("stateId", stateId);
      formData.append("token", localStorage.getItem("token"));
      this.bs.callAPI(formData, "getAllCities").subscribe(
        //this.myapp.isLoadingResults = false;
        (response: any) => {
          //console.log(response);
          if (response.status == 1) {
            /*this.myapp.showSuccessAlert = true;
            this.myapp.successMsg = response.message;
            this.myapp.isLoadingResults = false;*/
            this.allCities = response.data;
            //console.log(this.allCities);
          } else {
            this.myapp.showErrorAlert = true;
            this.myapp.errorMsg = response.message;
          }
        },
        error => {
          console.log(error);
        }
      );
    } else this.allCities = [];
  }
}
